import { BaseMockApi } from "./BaseMockApi";
import { getClosestLatLong } from "./helpers/locationHelpers";

const baseFilePath = '/mocked_responses/panoramaApi'

export class PanoramaMockApi extends BaseMockApi {
  constructor(setSnackbar) {
    super(setSnackbar, 'PanoramaApi');
  }

  async fetchImageryByProject(projectName, maxRecords = null, prefilter = null) {
    return this.get(`${baseFilePath}/get_collection_image_data_${projectName}.json`);
  }

  async fetchImageryByBounds(collectionName, gpsx1, gpsy1, gpsx2, gpsy2) {
    return this.get(`${baseFilePath}/get_collection_image_data_boundingbox_${collectionName}.json`);
  }

  async fetchNearestPanoImage(collection_name, latitude, longitude) {
    const closestImage = await getClosestLatLong(latitude, longitude, `${baseFilePath}/image_metadata.json`, 'Latitude_deg', 'Longitude_deg');
    return closestImage[0]
      ? closestImage[0]
      : this.outsideOfDemoAreaResponse('Image');
  }

  async fetchPanoImage(imageFolder, imageFilename, bucketName) {
    const image_metadata = await this.get(`${baseFilePath}/image_metadata.json`);
    let image = image_metadata.find(image => image.Frame_filename === imageFilename);
    if (image) {
      let url = `http://localhost:3000/mocked_responses/panoramaApi/images/${image.id}.jpg`;
      return url
    }
    this.outsideOfDemoAreaResponse('Image');
  }

  async fetchPanoImageData(imageId) {
    const image_metadata = await this.get(`${baseFilePath}/image_metadata.json`);
    let image = image_metadata.find(image => image.id === Number(imageId));
    return image
      ? image
      : this.outsideOfDemoAreaResponse('Image');
  }
}