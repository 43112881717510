import React, { useContext } from "react";
import MapStage from "../features/map-stage/MapStage";
import EsriMap from "../features/map-stage/EsriMap";
import PhotoSphereViewer from "../features/photo-sphere-viewer/PhotoSphereViewer";
import PotreeViewer from "../features/potree-viewer/PotreeViewer";
import RequestGroundCollection from "../features/request-ground-collection/RequestGroundCollection";
import DummyTestSection from "../features/dummy-test-section/DummyTestSection";
import MeasureTools from "../features/measure-tools/MeasureTools";
import { InteractiveSection } from "../Enums";
import { ErrorBoundary } from "react-error-boundary";
import AssetInfoHeader from "../features/asset-info-panel/AssetInfoHeader";
import AssetFormHeader from "../features/asset-form-panel/AssetFormHeader";
import AssetFormPanel from "../features/asset-form-panel/AssetFormPanel";
import AssetInfoPanel from "../features/asset-info-panel/AssetInfoPanel";
import { LayoutContext } from "../Contexts/LayoutContext";

const getSectionComponent = ({ section }) => {
  switch (section) {
    case InteractiveSection.AssetInfoHeader: return (<AssetInfoHeader />);
    case InteractiveSection.AssetInfoPanel: return (<AssetInfoPanel />);
    case InteractiveSection.PoleFormHeader: return (<AssetFormHeader />);
    case InteractiveSection.AssetFormPanel: return (<AssetFormPanel />);
    case InteractiveSection.RequestGroundCollection: return (<RequestGroundCollection />);
    case InteractiveSection.Map: return (<MapStage />);
    case InteractiveSection.EsriMap: return (<EsriMap />);
    case InteractiveSection.Panorama: return (<PhotoSphereViewer />);
    case InteractiveSection.PointCloud: return (<PotreeViewer />);
    case InteractiveSection.MeasureTools: return (<MeasureTools />);
    case InteractiveSection.Dummy: return (<DummyTestSection />);
    // render the empty toolbar
    default:
      return (
        <></>
      )
  }
}

const logError = (error, info) => {
  console.log(`BOUNDARY ERROR: ${error}, ${info}`)
};

const InteractiveSectionSelector = ({ section }) => {
  const layoutContext = useContext(LayoutContext);
  const [snackbar, setSnackbar] = layoutContext.snackbar;

  const fallbackRender = ({ error, resetErrorBoundary }) => {
    if (!snackbar.open) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error'
      })
    }
  }

  return <ErrorBoundary
    fallbackRender={fallbackRender}
    onError={logError}
    className='error-boundary'
  >
    {getSectionComponent({ section })}
  </ErrorBoundary>
};

export default InteractiveSectionSelector;
