import { measurementTools } from "../../utils/reusable-functions";

export async function getClosestLatLong(latitude,
    longitude,
    filepath,
    lat_variable_name = 'latitude',
    long_variable_name = 'longitude',
    results = 1) {
    const response = await fetch(filepath);
    const central_lat_longs = await response.json();
    let distances = [];
    central_lat_longs.forEach(element => {
        let distance = Number(measurementTools.calculateDistanceInFeet(latitude, longitude, element[lat_variable_name], element[long_variable_name]));
        if (distance < 500) {
            distances.push({
                index: central_lat_longs.indexOf(element),
                distance: Number(measurementTools.calculateDistanceInFeet(latitude, longitude, element[lat_variable_name], element[long_variable_name]))
            });
        }
    });
    distances.sort((a, b) => a.distance - b.distance); // Sort distances in ascending order

    let closestResults = [];
    for (let i = 0; i < Math.min(results, distances.length); i++) {
        closestResults.push(central_lat_longs[distances[i].index]);
    }

    return closestResults;
}
