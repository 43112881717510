/* eslint-disable */
// will load on map-one route. 
import React, { useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { PositionContext, StoreContext } from '../Contexts';
import { useLocation } from "react-router-dom";
import { InteractiveSection, LayoutOption } from "../Enums";
import { LayoutContext } from "../Contexts/LayoutContext";
import { useApis } from "../Contexts/ApiServiceContext";


const MapOne = () => {
  const { projectApi } = useApis();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project-id");
  const query_viewArea = JSON.parse(queryParams.get("viewArea"));
  const appContext = useContext(StoreContext);
  const positionContext = useContext(PositionContext);
  const layoutContext = useContext(LayoutContext);
  const [, setLayout] = layoutContext.layout;
  const [, setMainView] = layoutContext.mainView;
  const [, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;
  const [, setViewArea] = positionContext.viewArea;

  const fetchDataIfNeeded = async () => {
    if (!availableProjects.length) {
      const projectList = await projectApi.fetchProjectList();
      setAvailableProjects(projectList);
      const filteredArray = projectList.filter(obj => obj.collection_name === projectId);
      await setActiveProject(filteredArray[0]);
    }
  }

  useEffect(() => {
    fetchDataIfNeeded();
    setLayout(LayoutOption.FullPage);
    setMainView(InteractiveSection.Map);
    if (query_viewArea?.latitude && query_viewArea?.longitude && query_viewArea?.zoom) {
      setViewArea(query_viewArea);
    }
  }, []);

  return (
    <Stage />
  );
}

export default MapOne;
