/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useContext, useEffect, useState } from "react";
import {
    LinearProgress,
    Container,
} from '@mui/material';
import AssetDetailsDisplaySection from '../../custom-components/AssetDetailsDisplaySection';
import { LayoutContext } from '../../Contexts/LayoutContext';

function AssetInfoPanel() {
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext)
    const [activePole] = appContext.activePole;
    const [loading, setLoading] = layoutContext.loading;

    useEffect(() => {
        if (activePole && Object.keys(activePole).length) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [activePole]);

    if (!(activePole && Object.keys(activePole).length)) {
        return (
            <div className="active-pole-info-panel">
                {loading && <LinearProgress />}
            </div>
        );
    } else {
        return (
            <Container className="active-pole-info-panel">
                <AssetDetailsDisplaySection asset={activePole.asset_properties} title={activePole.asset_name} />
            </Container>
        );
    }
}

export default AssetInfoPanel;