import React, { useState, useEffect, useContext } from "react";
import {
    IconButton,
    TextField,
    InputAdornment,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { StoreContext } from "../../../Contexts";
import { useAssetServices } from "../../../services";

const SearchTool = ({ currentMap }, ...props) => {
    const { fetchAsset } = useAssetServices();
    const { Maps } = window.Microsoft;
    const appContext = useContext(StoreContext);
    const [, setActivePole] = appContext.activePole;
    const [searchManager, setSearchManager] = useState();
    const [searchValue, setSearchValue] = useState();

    const handleSearch = async () => {
        const latLongRegex = /^-?\d{1,2}\.\d+,-?\d{1,3}\.\d+$/;

        if (latLongRegex.test(searchValue)) {
            await geoCode(searchValue);
        } else {
            await getPoleLocation();
        }
    }

    const getPoleLocation = async () => {
        let pole = await fetchAsset(searchValue);
        if (pole) {
            currentMap.setView({
                center: new Maps.Location(pole.y_latitude || pole.lat, pole.x_longitude || pole.lng),
                zoom: 100
            });
            setActivePole(pole);
        }
        else {
            alert(`Pole ${searchValue} does not exist`);
        }
    }

    const geoCode = (where) => {
        let searchRequest = {
            where: where,
            callback: (r, userData) => {
                if (r && r.results && r.results.length > 0) {
                    let locs = [];
                    r.results.map((result => locs.push(result.location)));
                    if (r.results.length === 1) {
                        currentMap.setView({ bounds: r.results[0].bestView, padding: 5 });
                    } else {
                        alert(`Multiple results found, refine search: ${JSON.stringify(locs)}`)
                    }
                }
            },
            errorCallback: (e) => {
                alert(`Invalid search value: ${e.where}`);
                console.log(e);
            }
        };
        searchManager.geocode(searchRequest);
    }

    useEffect(() => {
        const suggestionSelected = (result) => {
            setSearchValue(result.title);
            currentMap.setView({ bounds: result.bestView });
        }

        const initMenu = () => {
            Maps.loadModule(['Microsoft.Maps.AutoSuggest', 'Microsoft.Maps.Search'], function () {
                var manager = new Maps.AutosuggestManager({ map: currentMap });
                manager.attachAutosuggest('#searchBox', '#searchBoxContainer', suggestionSelected);
                setSearchManager(new Maps.Search.SearchManager(currentMap));
            });
        }

        initMenu();
    }, [Maps, currentMap]);

    return (
        <div id='searchBoxContainer'>
            <TextField
                id='searchBox'
                size="small"
                placeholder="Address,GPS,Pole ID"
                onChange={(e) => setSearchValue(e.target.value)}
                sx={{
                    background: 'white',
                    borderRadius: '5px',
                    input: {
                        borderRadius: '5px',
                        fontSize: '10pt'
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label="search"
                                disabled={!searchValue}
                                onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>)
}

export default SearchTool;