/* eslint-disable */
// will load on map-one route. 
import React, { useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { StoreContext } from '../Contexts';
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";


const EsriMapView = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project-id");
  const appContext = useContext(StoreContext);
  const [, setLayout] = appContext.layout;
  const [, setMainView] = appContext.mainView;
  const [, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;

  const fetchDataIfNeeded = async () => {
    if (!availableProjects.length) {
      const projectList = await ProjectApi.fetchProjectList();
      setAvailableProjects(projectList);
      const filteredArray = projectList.filter(obj => obj.collection_name === "Knoxville_CHARTER_POC"); // projectId
      await setActiveProject(filteredArray[0]);
    }
  }

  useEffect(() => {
    fetchDataIfNeeded();
    setLayout(LayoutOption.FullPage);
    setMainView(InteractiveSection.EsriMap);
  }, []);

  return (
    <Stage />
  );
}

export default EsriMapView;
