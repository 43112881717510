import { BaseMockApi } from "./BaseMockApi";

const baseFilePath = '/mocked_responses/workflowStatusApi'

export class WorkflowStatusMockApi extends BaseMockApi {
    constructor(setSnackbar) {
        super(setSnackbar, 'WorkflowStatusApi');
    }

    async fetchWorkflowStatuses() {
        return this.get(`${baseFilePath}/get_workflow_statuses.json`);
    }
}
