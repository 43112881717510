/* eslint-disable */
import React, { useContext } from "react";
import InteractiveSectionSelector from '../../utils/InteractiveSectionSelector';
import { LayoutContext } from '../../Contexts/LayoutContext';

function Stage() {
  const layoutContext = useContext(LayoutContext);
  const [mainView] = layoutContext.mainView;
  return (
    <div className='stage'>
      <InteractiveSectionSelector section={mainView} />
    </div>
  );
}

export default Stage;