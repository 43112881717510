import { useApis } from "../Contexts/ApiServiceContext";

const useWorkareaServices = () => {
    const { assetWorkflowApi, workareaApi } = useApis();

    const addWorkareaWithAssets = async (workarea, assetIds, selectedOptions) => {
        let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;
        workarea.created_by = currentUsername;
        if (!workarea.workarea_id) {
            try {
                workarea.workarea_id = (await workareaApi.addWorkarea(workarea)).workarea_id;
            } catch (e) {
                console.log(e);
                throw Error(`Failed to add workarea`);
            }
        }
        await workareaApi.addWorkareaAssets(workarea.workarea_id, assetIds, currentUsername);
        await updateWorkareaStatuses(workarea, selectedOptions)
    }

    const updateWorkareaStatuses = async (workarea, selectedOptions) => {
        let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;
        let workarea_workflow_status = {
            assignee: selectedOptions.assignee === -1 ? null : selectedOptions.assignee,
            workflow_status_id: selectedOptions.workarea_workflow_status_id ?? null,
            workarea_id: workarea.workarea_id,
            created_by: currentUsername,
        }
        await workareaApi.updateWorkareaStatuses({ workarea_statuses: [workarea_workflow_status] });
    }

    const updateWorkareaAssetsAndWorkflowStatuses = async (workareaId, assetIds, selectedOptions) => {
        let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;
        let existingAssets = await assetWorkflowApi.getAssetsByKeyValue('workarea_id', workareaId);
        const existingAssetIds = new Set(existingAssets.map(asset => asset.id));
        const newAssetIds = assetIds.filter(id => !existingAssetIds.has(id));
        await workareaApi.addWorkareaAssets(workareaId, newAssetIds, currentUsername);

        return updateAssetWorkflowStatuses(assetIds, selectedOptions);
    }

    const updateAssetWorkflowStatuses = async (assetIds, selectedOptions) => {
        let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;

        let asset_workflow_statuses = assetIds.map(asset_id => {
            return {
                asset_id,
                workflow_status_id: selectedOptions.workflow_status_id === -1 ? null : selectedOptions.workflow_status_id,
                assignee: selectedOptions.assignee === -1 ? null : selectedOptions.assignee,
                created_by: currentUsername
            }
        });
        await assetWorkflowApi.updateAssetWorkflowStatuses({ asset_workflow_statuses: asset_workflow_statuses });
    }

    const fetchWorkareaAssets = async (workarea_id) => {
        return await assetWorkflowApi.getAssetsByKeyValue('workarea_id', workarea_id);
    }

    return {
        addWorkareaWithAssets,
        updateWorkareaStatuses,
        updateWorkareaAssetsAndWorkflowStatuses,
        updateAssetWorkflowStatuses,
        fetchWorkareaAssets
    };
};

export default useWorkareaServices;