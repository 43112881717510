/* eslint-disable */
// Bring in React functionality, shared contexts, and enumerations from example in BingMap.js
import React, { useEffect, useRef, useState, useContext } from 'react';
import { PositionContext, StoreContext } from '../../Contexts';
import { InteractiveSection, LayoutOption } from "../../Enums";
// to bring in pole data and panorama/lidar points
import { usePanoramaUtils, usePointCloudUitls } from "../../utils/";
import { useNavigate } from "react-router-dom";
// bring arcgis core functionality: maps, views, layers, reactiveUtils for resizing window
import Map from '@arcgis/core/Map';
import Basemap from '@arcgis/core/Basemap';
import MapView from '@arcgis/core/views/MapView';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
// for converting map bounds
import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
// add widgets for basemap selection, listing layers, locate tool, expand widgets, (search eventually)
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import BasemapLayerList from '@arcgis/core/widgets/BasemapLayerList';
import LayerList from '@arcgis/core/widgets/LayerList';
import Locate from '@arcgis/core/widgets/Locate';
import Expand from '@arcgis/core/widgets/Expand';
import Sketch from '@arcgis/core/widgets/Sketch';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";
// DeckGL for drawing layers from https://developers.arcgis.com/javascript/latest/sample-code/custom-lv-deckgl/
import { DeckLayer } from '@deck.gl/arcgis';
import { ScatterplotLayer } from '@deck.gl/layers';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { useApis } from '../../Contexts/ApiServiceContext';
import { useAssetServices } from '../../services';
import { LayoutContext } from '../../Contexts/LayoutContext';
import { Button, Stack, Tooltip } from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
// import { ExportOutlined } from 'jimu-icons/outlined/editor/export'; // replace DownloadIcon?

/////////////////////////////////////////////
// Build MapComponent, bringing in contexts//
// setting coordinate properties as floats //
// create Deck Layers for use in view      //
/////////////////////////////////////////////
const MapComponent = () => {
  // to achieve parity with current version, pull in all of the BingMap.js references?
  // note: deleted (most) unused constants
  const { pointCloudApi, panoramaApi } = useApis();
  const { fetchAssets, fetchAsset, exportAssets } = useAssetServices();
  const { openPointCloud } = usePointCloudUitls();
  const { getPanoramas } = usePanoramaUtils();
  const appContext = useContext(StoreContext);
  const layoutContext = useContext(LayoutContext);
  const positionContext = useContext(PositionContext);

  const [mainView] = layoutContext.mainView;
  const [layout, setLayout] = layoutContext.layout;
  const [, setSidebarModules] = layoutContext.sideBarModules;
  const [, setLoading] = layoutContext.loading;
  const [, setSnackbar] = layoutContext.snackbar;
  const [polygonDefined, setPolygonDefined] = useState();

  const [, setLatitude] = positionContext.latitude;
  const [, setLongitude] = positionContext.longitude;
  const [, setImageId] = appContext.imageId;
  const [, setActivePointCloud] = appContext.activePointcloud;
  const [, setActivePole] = appContext.activePole;
  const [activeProject] = appContext.activeProject;
  const activeProjectRef = useRef({});
  activeProjectRef.current = activeProject;
  const mapRef = useRef();

  const navigate = useNavigate();

  const [poles, setPoles] = useState();
  const [panoramas, setPanoramas] = useState();
  const [lidar, setLidar] = useState();
  const [map, setMap] = useState();
  const mapInstanceRef = useRef();
  mapInstanceRef.current = map;
  const [mapBounds, setMapBounds] = useState();
  const [selectetdPoles, setSelectedPoles] = useState();
  const [deckLayer, setDeckLayer] = useState();

  const downloadAssets = async () => {
    setLoading(true);
    try {
      await exportAssets([]);
    }
    catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      });
    }
    setLoading(false);
  }

  const fetchData = async () => {
    let poleData = await fetchAssets();
    let panoramaData = await getPanoramas(activeProject.collection_name, mapBounds);
    // let panoramaData = await panoramaApi.fetchImageryByProject(activeProject.collection_name);
    let lidarData = await getPanoramas(activeProject.collection_name, mapBounds);
    // let lidarData = await panoramaApi.fetchImageryByProject(activeProject.collection_name);
    setPoles(poleData);
    setPanoramas(panoramaData);
    setLidar(lidarData);
  };

  // how often are these useEffects processed? Is that what causes the big slowdown?
  // after the first load, these conditions are still true...so do they keep loading?
  useEffect(() => { if (mapBounds && activeProject) { fetchData() } }, [mapBounds, activeProject]);

  useEffect(() => { if (poles && panoramas && lidar && !deckLayer) { createDeckLayer() } }, [poles, panoramas, lidar]);

  /******************************************************
   * Create data layer; convert lat/lon to Float, 
   * create scatterplots, heatmap layer, add to map
   ******************************************************/

  const createDeckLayer = (visibleLayers = ['Poles', 'Panoramas', 'Lidar']) => {
    poles.forEach(point => {
      point.x_longitude = parseFloat(point.x_longitude);
      point.y_latitude = parseFloat(point.y_latitude);
    });

    panoramas.forEach(image => {
      image.Longitude_deg = parseFloat(image.Longitude_deg);
      image.Latitude_deg = parseFloat(image.Latitude_deg);
    });

    let polesDeck = new ScatterplotLayer({
      id: 'Poles',
      data: poles,
      getPosition: d => [d.x_longitude, d.y_latitude],
      getFillColor: [255, 255, 255],
      radiusMinPixels: 4,
      radiusMaxPixels: 8,
      autoHighlight: true,
      pickable: true,
      onClick: async (e) => await onPoleClick(e)
    })

    let panoDeck = new ScatterplotLayer({
      id: 'Panoramas',
      data: panoramas,
      getPosition: d => [d.Longitude_deg, d.Latitude_deg],
      getFillColor: [100, 149, 237],
      radiusMinPixels: 7,
      radiusMaxPixels: 21,
      autoHighlight: true,
      pickable: true,
      onClick: async (e) => await fetchNearestPanoImage(e)
    })

    let lidarDeck = new HeatmapLayer({
      id: 'Lidar',
      data: lidar,
      aggregation: 'SUM',
      getPosition: d => [d.Longitude_deg, d.Latitude_deg],
      radiusPixels: 120,
      // colorRange: [[46, 172, 102], [126, 188, 87], [179, 205, 65], [223, 221, 25], [255, 237, 0]],
      colorRange: [[46, 172, 102, 150]],
      // pickable: true, //does setting this to pickable cause basemap glitches?
      onClick: async (e) => await openNearestLidar(e)
    })

    let layers = []
    visibleLayers.map(layer => {
      switch (layer) {
        case 'Poles':
          layers.push(polesDeck);
          break;
        case 'Panoramas':
          layers.push(panoDeck);
          break;
        case 'Lidar':
          layers.push(lidarDeck);
          break;
        default:
          break;
      }
    })

    const visibleDeck = new DeckLayer({
      'title': 'Pole Insights',
      'deck.layers': layers,
      'listMode': 'hide'
    });
    mapInstanceRef.current.add(visibleDeck);
    setDeckLayer(visibleDeck);

    setMap(mapInstanceRef.current);
  }

  /******************************************************
   * When a pole is clicked, call this function to 
   * bring up the details panel, where the link to potree lives
   ******************************************************/

  const onPoleClick = async (e) => {
    setLoading(true);
    setActivePole(null);
    let selectedPole = await fetchAsset(e.object.asset_uuid);
    setActivePole(selectedPole);
    setLatitude(e.object.y_latitude);
    setLongitude(e.object.x_longitude);
    if ([InteractiveSection.Map, InteractiveSection.EsriMap].includes(mainView)) {
      setLayout(LayoutOption.SideBarRight);
      setSidebarModules(prevState => ({
        ...prevState,
        right_header: InteractiveSection.AssetInfoHeader,
        right_top: InteractiveSection.AssetInfoPanel
      }));
    }
    else {
      const selectedPointCloudData = await pointCloudApi
        .fetchPointCloudByGPSLocation(activeProject.collection_name, e.object.y_latitude, e.object.x_longitude);
      await openPointCloud(setActivePointCloud,
        mainView,
        navigate,
        selectedPointCloudData[0],
        e.object.y_latitude,
        e.object.x_longitude,
        e.object.asset_uuid);
    }
    setLoading(false);
  }

  const fetchNearestPanoImage = async (e) => {
    setImageId(e.object.id);
    if (layout === LayoutOption.FullPage) {
      window.open(`/pano-imagery-one?image_id=${e.object.id}`,
        '_blank');
    }
  }

  const openNearestLidar = async (e) => {
    const selectedPointCloudData = await pointCloudApi
      .fetchPointCloudByGPSLocation(activeProject.collection_name, e.coordinate[1], e.coordinate[0]);
    setLatitude(e.coordinate[1]);
    setLongitude(e.coordinate[0]);
    await openPointCloud(setActivePointCloud,
      mainView,
      navigate,
      selectedPointCloudData[0],
      e.coordinate[1],
      e.coordinate[0]);
  }

  /******************************************************
   * useEffect to create map view 
   * includes all widgets, dummy layers, sketch tool...
   ******************************************************/

  useEffect(() => {

    // layer for selecting poles
    const graphicsLayer = new GraphicsLayer();
    graphicsLayer.title = "Selection Layer";
    graphicsLayer.listMode = "hide";

    // create new map with hybrid satellite imagery and labels
    const mapInstance = new Map({
      basemap: 'hybrid',
      layers: [graphicsLayer]
    });

    // create new view, centered on Sevierville and zoomed in
    const view = new MapView({
      container: mapRef.current,
      map: mapInstance,
      center: [-83.4595119, 36.043351],
      zoom: 11
    });

    const layerList = new LayerList({
      view: view,
    });

    /******************************************************
     * Add dummy layers for decks insdie of Pole Insights
     ******************************************************/

    // create empty FeatureLayer for turning on and off poles
    const dummyPoleLayer = new FeatureLayer({
      // create an instance of esri/layers/support/Field for each field object
      title: "Poles",
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid"
        }
      ],
      geometryType: "point",
      spatialReference: { wkid: 4326 },
      source: [] // adding an empty feature collection
    });
    mapInstance.add(dummyPoleLayer);

    // create empty FeatureLayer for turning on and off panoramas
    const dummyPanoLayer = new FeatureLayer({
      // create an instance of esri/layers/support/Field for each field object
      title: "Panoramas",
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid"
        }
      ],
      geometryType: "point",
      spatialReference: { wkid: 4326 },
      source: [] // adding an empty feature collection
    });
    mapInstance.add(dummyPanoLayer);

    // create empty FeatureLayer for turning on and off panoramas
    const dummyLidarLayer = new FeatureLayer({
      // create an instance of esri/layers/support/Field for each field object
      title: "Lidar",
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid"
        }
      ],
      geometryType: "point",
      spatialReference: { wkid: 4326 },
      source: [] // adding an empty feature collection
    });
    mapInstance.add(dummyLidarLayer);

    /******************************************************
     * Create sketch tool, give it graphicsLayer to work with
     ******************************************************/

    // this array will keep track of selected feature objectIds to
    // sync the layerview feature effects and feature table selection
    let features = [];

    view.when(() => {
      const sketch = new Sketch({
        layer: graphicsLayer,
        view: view,
        layout: "vertical",
        // graphic will be selected as soon as it is created
        creationMode: "update"
      });

      view.ui.add(sketch, "top-left");
      // Setting the sketch's visible elements
      // Removes unwanted creation and selection tools, settings menu
      // Leaves polygon tool
      sketch.visibleElements = {
        createTools: {
          point: false,
          polyline: false,
          circle: false,
          rectangle: false
        },
        selectionTools: {
          "rectangle-selection": false,
          "lasso-selection": false
        },
        settingsMenu: false,
        undoRedoMenu: false,
        duplicateButton: false
      }

      // Listen to sketch widget's create event.
      sketch.on("create", function (event) {
        // check if the create event's state has changed to complete indicating
        // the graphic create operation is completed.
        if (event.state === "complete") {
          setPolygonDefined(true);
          // remove the graphic from the layer. Sketch adds
          // the completed graphic to the layer by default.
          // graphicsLayer.remove(event.graphic);
          // use the graphic.geometry to query features that intersect it
          //selectFeatures(event.graphic.geometry);
        }
      });

      // Listen to sketch widget's update event
      sketch.on("update", function (event) {
        // if the sketch updated and the graphicsLayer no longer has any elements
        if ((event.state === "complete") && (graphicsLayer.graphics.length === 0)) {
          // set the polygon to undefined, which turns off the download button
          setPolygonDefined(false);
        }
      });
    });

    // set the view bounds, every time there is a new extent. Used in fetching pole data.
    view.watch('extent', (newExtent) => {
      const geographicExtent = webMercatorToGeographic(newExtent);
      let newBoundsObject = {
        northEast: {
          latitude: geographicExtent.ymax,
          longitude: geographicExtent.xmax,
        },
        southWest: {
          latitude: geographicExtent.ymin,
          longitude: geographicExtent.xmin,
        }
      }
      setMapBounds(newBoundsObject);
    });

    // creates list of visible basemaps...where you can turn labels on and off
    let basemapLayerList = new BasemapLayerList({
      view: view,
      container: document.createElement("div")
    });

    // place holder for NearMap basemap? Currently Open Street Map Blueprint
    const customBasemap = new Basemap({
      portalItem: {
        id: "46a87c20f09e4fc48fa3c38081e0cae6"
      }
    })

    // create a BasemapGallery widget instance and set its container to a div element
    // Update the list of basemaps displayed by setting the source property of the BasemapGallery
    // Can include basemaps referenced by their PortalItem, or from a well known basemap ID
    const basemapGallery = new BasemapGallery({
      view: view,
      container: document.createElement("div"),
      source: [Basemap.fromId("streets-vector"), Basemap.fromId("hybrid"), customBasemap] // autocasts to LocalBasemapsSource
    });

    // Create an Expand instance, set content to DOM node of the basemap gallery widget
    // Use an Esri icon font to represent the content inside of the Expand widget
    const bgExpand = new Expand({
      view: view,
      content: basemapGallery
    });

    // Create an Expand instance, set content to DOM node of the basemap layer list widget
    const baseLayerListExpand = new Expand({
      view: view,
      content: basemapLayerList
    });

    // Create an Expand instance, set content to DOM node of the feature layer list widget
    const layerListExpand = new Expand({
      view: view,
      content: layerList
    });

    //turn on a zoom to my position feature, because... this will be needed in the field?
    const locate = new Locate({
      view: view,
      rotationEnabled: false,
      goToOverride: function (view, options) {
        options.target.scale = 1500;
        return view.goTo(options.target);
      }
    });

    // replicating BV GeoAI view, adding basemap toggle, center on location, zoom to upper-right
    view.ui.add(layerListExpand, { position: "top-left" });
    view.ui.add(bgExpand, { position: "top-right" });
    view.ui.add(baseLayerListExpand, { position: "top-right" });
    view.ui.add(locate, "top-right"); // hiding locate for now, clutters up map
    view.ui.move("zoom", "top-right");

    setMap(mapInstance);
  }, [])

  /******************************************************
   * useEffect to listen for changes to which layers are visible 
   * Creates new deck layer containing all visible layers
   ******************************************************/

  useEffect(() => {
    if (deckLayer) {
      reactiveUtils.when(
        () => {
          let foundDeckLayersString = mapInstanceRef.current.layers
            .find(layer => {
              return layer.title === "Pole Insights";
            }).deck.layers
            .map(x => x.id).sort().join(',');

          let visibleLayersString = mapInstanceRef.current.layers
            .filter((layer) => layer.visible && !['Pole Insights', 'Selection Layer'].includes(layer.title))
            .map(layer => layer.title).sort().join(',');

          return foundDeckLayersString !== visibleLayersString;
        },
        () => {
          // Do something with the visibleLayers Collection

          const layerCatalog = mapInstanceRef.current.layers.filter((layer) => layer.visible && !['Pole Insights', 'Selection Layer'].includes(layer.title)).map(layer => layer.title);
          const foundLayer = mapInstanceRef.current.layers.find(layer => {
            return layer.title === "Pole Insights";
          });
          if (foundLayer) {
            foundLayer.destroy();
          }
          createDeckLayer(layerCatalog);
        }
      );
    }
  }, [deckLayer])

  const mapStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    right: 0,
  };

  return (
    <div>
      <div className="map-container" ref={mapRef} style={mapStyle} />
      {polygonDefined &&
        <Stack sx={{ position: 'absolute', top: 250, left: 10, background: 'transparent' }} spacing={.5}>
          <Tooltip title="Download Selected Poles" arrow>
            <Button
              sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
              aria-label="download selected poles"
              onClick={downloadAssets} >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Stack>
      }
    </div>
  );
};

export default MapComponent;