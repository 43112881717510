import { useApis } from "../Contexts/ApiServiceContext";

const usePanoramaUtils = () => {
    const { panoramaApi } = useApis();

    async function getPanoramas(collection_name, bounds) {
        // get the imagery in the area. 
        const imageryInArea = await panoramaApi.fetchImageryByBounds(
            collection_name,
            bounds.northEast.latitude,
            bounds.northEast.longitude,
            bounds.southWest.latitude,
            bounds.southWest.longitude
        );

        return imageryInArea || [];
    }

    return { getPanoramas };
};

export default usePanoramaUtils;