export class BaseMockApi {
    constructor(setSnackbar, serviceName) {
        this.setSnackbar = setSnackbar;
        this.serviceName = serviceName;

        return new Proxy(this, {
            get: (target, prop) => {
                if (typeof target[prop] === 'undefined') {
                    return () => this.unimplementedResponse(prop);
                }
                return target[prop];
            },
        });
    }

    async get(endpoint) {
        const response = await fetch(endpoint);
        try {
            const data = await response.json();
            return data;
        } catch (error) {
            return this.outsideOfDemoAreaResponse();
        }
    }


    unimplementedResponse(methodName) {
        this.setSnackbar({
            message: `The method "${methodName}" of service "${this.serviceName}" is not implemented in demo mode`,
            severity: "warning",
        });
        return Promise.resolve(this.getEmptyResponse());
    }

    outsideOfDemoAreaResponse(dataType, responseOverride = {}) {
        this.setSnackbar({
            open: true,
            severity: 'warning',
            message: dataType ? `${dataType} outside of demo area` : 'Outside of demo area'
        })
        return responseOverride;
    }

    getEmptyResponse() {
        // Define a default empty response structure
        return {}; // You can modify this to match the structure expected by your API
    }
}
