import React, { useEffect, useState, useContext } from "react";
import {
    FormControl,
    TextField,
    IconButton,
} from '@mui/material';
import { SquareFoot } from '@mui/icons-material';
import { locationFunctions, measurementTools } from "../utils/reusable-functions";
import { StoreContext } from "../Contexts";

const MeasurementInputControl = ({ assetId, propertyKey, property, handleChange }) => {
    const appContext = useContext(StoreContext);
    const [measurementMap, setMeasurementMap] = appContext.measurementMap;
    const [activePointcloud] = appContext.activePointcloud;
    const [activePole] = appContext.activePole;
    const [potreeViewer] = appContext.potreeViewer;
    const [, setPotreeMode] = appContext.potreeMode;
    const [, setMarkerPlacement] = appContext.markerPlacement;
    const [measurementValues, setMeasurementValues] = useState({});

    useEffect(() => {
        const updateMeasurements = () => {
            let updatedValues = {};
            property.measurement_types.forEach(measurement => {
                updatedValues[measurement.measurement_type] = getMeasurement(measurement.measurement_type);
                let coordinates = (measurementMap[`${propertyKey}_${assetId}`])?.points[0].position;
                if (coordinates) {
                    handleChange(propertyKey, coordinates);
                }
            });
            setMeasurementValues(updatedValues);
        };

        updateMeasurements();
    }, [measurementMap, property.measurement_types, assetId, propertyKey]);

    const getMeasurement = (measurementType) => {
        let base = measurementMap[`point_cloud_coordinates_${activePole.asset_uuid}`];
        let coordinates = measurementMap[`${propertyKey}_${assetId}`];

        if (!coordinates || !base) {
            return '';
        }
        switch (measurementType) {
            case 'height':
                return measurementTools.getHeight(coordinates, base, activePointcloud.is_metric);
            case 'elevation':
                return measurementTools.getElevation(coordinates, activePointcloud.is_metric);
            case 'length':
                return measurementTools.getLength(coordinates, base, activePointcloud.is_metric);
            case 'distance':
                return measurementTools.getDistance(coordinates, base, activePointcloud.is_metric);
            case 'azimuth':
                return measurementTools.getAzimuth(coordinates, base, potreeViewer.getProjection());
            case "latitude":
                let [, latitude] = locationFunctions.getLatLngOfCoordinate(coordinates, activePointcloud.wkt);
                return latitude;
            case "longitude":
                let [longitude] = locationFunctions.getLatLngOfCoordinate(coordinates, activePointcloud.wkt);
                return longitude;
            default:
                return '';
        }
    };

    const handleSetPointMode = (measurementLabel) => {
        if (!Object.keys(measurementMap).includes(measurementLabel)) {
            setPotreeMode(true);
            const measurement = potreeViewer.measuringTool.startInsertion({
                showDistances: false,
                showAngles: false,
                showCoordinates: false,
                showArea: false,
                closed: true,
                maxMarkers: 1,
                name: 'Point'
            });

            measurement.addEventListener('marker_dropped', (event) => {
                setMarkerPlacement([...potreeViewer.scene.measurements]);
                setMeasurementMap(prevMap => ({
                    ...prevMap,
                    [measurementLabel]: event.target,
                }));
            });
        }
    }

    return (
        <div className="measure-area" key={`${property.id}_${assetId}`} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div>
                <IconButton onClick={() => handleSetPointMode(`${propertyKey}_${assetId}`)}
                    style={{ alignSelf: 'flex-start' }}
                    disabled={!handleSetPointMode}>
                    <SquareFoot sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'rgb(79, 111, 255)',
                        },
                    }} />
                </IconButton>
                {property.label}
            </div>
            {property.measurement_types.map((measurement, index) => (
                <FormControl key={index} size="small" fullWidth style={{ marginTop: index > 0 ? '5px' : '0' }}>
                    <TextField
                        id={`${propertyKey}_${assetId}_${measurement}`}
                        value={measurementValues[measurement.measurement_type]
                            ? `${measurementValues[measurement.measurement_type]} ${measurement.unit ?? ''}`
                            : ''}
                        label={measurement.measurement_type}
                        variant="outlined"
                        size="small"
                        disabled
                        InputProps={{
                            endAdornment: property.unit ? property.unit : null,
                        }}
                    />
                </FormControl>
            ))}
        </div>
    );
}

export default MeasurementInputControl;