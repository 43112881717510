import { BaseMockApi } from "./BaseMockApi";

const baseFilePath = '/mocked_responses/userApi'

export class UserMockApi extends BaseMockApi {
    constructor(setSnackbar) {
        super(setSnackbar, 'UserApi');
    }

    async fetchUsers() {
        return this.get(`${baseFilePath}/get_users.json`);
    }
}
