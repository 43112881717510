import { BaseMockApi } from "./BaseMockApi";
import { getClosestLatLong } from "./helpers/locationHelpers";
const baseFilePath = '/mocked_responses/pointCloudApi'

export class PointCloudMockApi extends BaseMockApi {
    constructor(setSnackbar) {
        super(setSnackbar, 'PointCloudApi');
    }

    async fetchAdjacentPointcloudTiles(pointcloudId, numberOfTiles) {
        const pointcloud_metadata = await this.get(`${baseFilePath}/point_cloud_files_metadata.json`);
        let pointcloud = pointcloud_metadata.find(file => file.id === pointcloudId);
        let closestTiles = await getClosestLatLong(pointcloud.central_lat, pointcloud.central_lng, `${baseFilePath}/point_cloud_files_metadata.json`, 'central_lat', 'central_lng', 6)
        let urls = []
        closestTiles.forEach(tile_data =>
            urls.push(`http://localhost:3000/mocked_responses/pointCloudApi/metadata_files/${tile_data.collection_name}/${tile_data.collection_set}/${tile_data.point_cloud_filename}/metadata.json`)
        )
        if (urls.length > 0) {
            let response = {
                urls,
                "min_lat": pointcloud.minBound_lat,
                "min_lng": pointcloud.minBound_lng,
                "max_lat": pointcloud.maxBound_lat,
                "max_lng": pointcloud.maxBound_lng
            };
            return response;
        }
        else {
            return this.outsideOfDemoAreaResponse('Point cloud');
        }
    }

    async fetchPointCloudByGPSLocation(collectionName, latitude, longitude) {
        const closestPointCloud = await getClosestLatLong(latitude, longitude, `${baseFilePath}/point_cloud_files_metadata.json`, 'central_lat', 'central_lng');
        return closestPointCloud.length > 0
            ? closestPointCloud
            : this.outsideOfDemoAreaResponse('Point cloud', []);
    }
}
