import { BaseMockApi } from "./BaseMockApi";

const baseFilePath = '/mocked_responses/assetWorkflowApi'

export class AssetWorkflowMockApi extends BaseMockApi {
    constructor(setSnackbar) {
        super(setSnackbar, 'AssetWorkflowApi');
    }

    async getAssetWorkflowStatuses(asset_ids) {
        return this.get(`${baseFilePath}/get_asset_workflow_status.json`);
    }
}
