import React, { useRef, useEffect, useContext, useState } from "react";
import { useApis } from "../../Contexts/ApiServiceContext";
import { StoreContext } from '../../Contexts';
import { ReactPhotoSphereViewer, CompassPlugin, MarkersPlugin } from 'react-photo-sphere-viewer';


// TODO: Need to upgrade to the latest https://github.com/mistic100/Photo-Sphere-Viewer


// Future versions - need to install and import the following plugins directly for PhotoSphereViewer
//import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin'; // OK - 11122024 - add markers plugin to show images around the current image in 360 view

const plugins = [[CompassPlugin], [MarkersPlugin, {markers: []}]]

function PhotoSphereViewer() {
    const appContext = useContext(StoreContext);
    const { panoramaApi } = useApis();
    const [imageId] = appContext.imageId;
    const [imageUrl, setImageUrl] = useState();
    const [imageData, setImageData] = useState();
    const photoSphereRef = useRef();

    const [initialLoad, setInitialLoad] = useState(true);

    let markersPlugs; // Markers plugin // OK
    let surroundingImages = []; // OK

    // OK - need to add cache in a newer photo sphere viewer library version
    // Cache.enabled = true;
    // Cache.ttl = 30; // minutes
    // Cache.maxItems = 6;

    useEffect(() => {
        const getNewImageData = async () => {
            let newImageData = await panoramaApi.fetchPanoImageData(imageId);         //console.log('OK::useEffect [imageId] - newImageData', newImageData, imageId, imageUrl, imageData);
            setImageData(newImageData);

            setImageUrl(await panoramaApi
                .fetchPanoImage(newImageData.Frame_folder,
                    newImageData.Frame_filename,
                    newImageData.Frame_bucket_name));

                    //console.log('OK::useEffect [imageId] - after setImageUrl', newImageData, imageId, imageUrl, imageData);
        };

        if (imageId) { 
            getNewImageData(); 
        }

    }, [imageId]);


    /**
     * Calculate straight-line distance between two points
     */
    const linearDistance = (x1, y1, x2, y2) => {

        return Math.sqrt((x2 - x1)*(x2 - x1) + (y2 - y1)*(y2 - y1));
    };

    /**
     * Convert degrees to radians
     */
    const deg2rad = (deg) => {
        return deg * (Math.PI/180);
    }

    /**
     * Convert radians to degrees
     */
    const rad2deg = (rad) => {
        return rad * 180 / Math.PI;
    }

    const getFtDistanceFromLatLon = (lat1, lon1, lat2, lon2) => {

        const R = 6371e3; // Radius of the Earth in m
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1); 
        const a = 
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon / 2) * Math.sin(dLon / 2); 
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
        const d = R * c * 3.28084; // Distance in ft
        return d; 
      }

      

    // Get angle bentween North and the line connecting observer and a given point (lon2, lat2)
    const getRelativeAngle = (lat1, lon1, observerAngle, lat2, lon2) => {
    
        // Calculate the relative angle to the main point
        const dx = lon2 - lon1; // dLon
        const dy = lat2 - lat1; // dLat
        let angle = Math.atan2(dy, dx) * (180 / Math.PI); // Angle in degrees from 0 (equator)

        //console.log('OK::getRelativeAngle() - raw Northing angle from observer to 360 image = ' + angle + '    Observer angle = ' + observerAngle);
        angle = 90 - angle; // Adjust to incorporate North heading
        
        const y = Math.sin(dx) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dx);
        let brng = Math.atan2(y, x) * (180 / Math.PI);
        //console.log('OK::getRelativeAngle() - adjusted Northing angle from observer to 360 image = ' + angle + '    Bearing = ' + brng);
        //return angle;
        return brng;
    }




    useEffect(() => {
        //...
        if (imageUrl) { 
            //console.log('OK::useEffect [imageUrl, imageData] set imageUrl = ' + imageUrl, ' imageData = ', imageData);
            updateImage(imageUrl, imageData); 
        }
    }, [imageUrl]);
    //}, [imageUrl, imageData]); // Removed update on imageData 



    // OK factored out from useEffect(()
    const updateImage = (imgUrl, imgData) => {

        // Clear current markers
        const markersPlugs = photoSphereRef.current?.getPlugin(MarkersPlugin);
        if (markersPlugs)  markersPlugs.clearMarkers();

        const headingDeg = Number(imgData?.Heading_deg ? imgData.Heading_deg : 0);
        const heading360 = headingDeg > 0 ? headingDeg : 360 + headingDeg;

        //console.log('OK::updateImage() - setting panorama ', imgUrl, imgData, initialLoad);


        let options = {
            caption: `Collection Id: ${imgData.collection_id} ` +
                `Collection Date: ${imgData.created_date} ` +
                `Heading: ${Number.parseFloat(imgData.Heading_deg || 0).toFixed(1)} ` +
                `Latitude: ${Number.parseFloat(imgData.Latitude_deg || 0).toFixed(6)} ` +
                `Longitude: ${Number.parseFloat(imgData.Longitude_deg || 0).toFixed(6)}`,
            panoData: {
                poseHeading: headingDeg, // Original: 0 to 360 - initially was poseHeading: heading360 
            },
            //position: { yaw: (heading360 / 180) * Math.PI, pitch: 0 } // OK: Original code
///            position: { yaw: (headingDeg / 180) * Math.PI, pitch: 0 } // Commented out to keep the look-at point constant between 360 scene transitions
        };

        if (initialLoad) {
            options = {
                ...options,
                position: { yaw: (headingDeg / 180) * Math.PI, pitch: 0 }
            }
        }


        photoSphereRef.current?.setPanorama(imgUrl, options);

        //console.log('OK::updateImage() - panorama set');

        setInitialLoad(false);

        fetchImagesAround(imgData);
    };


    const fetchImagesAround = async (imgData) => {

        // 0.00005 ~ 5.5 meters = 18 feet
        // 0.0001 = 11m = 36ft
        const radius = 0.00005; // Degrees
        const currentSurroundingImages = await panoramaApi.fetchImageryByBounds(imgData.collection_name, imgData.Latitude_deg - radius, imgData.Longitude_deg - radius, imgData.Latitude_deg + radius, imgData.Longitude_deg + radius); // 'DTE_EPSG8705_001'

        //console.log('OK::fetchImagesAround() - take 2: surroundingImages, currentSurroundingImages', surroundingImages, currentSurroundingImages);

        // TODO: Add markers to scene for each surrounding image (limit to 8)

        cacheSurroundingImages(currentSurroundingImages, imgData);
    }


    // OK - prefetch images (in-process) and cache in browser memory
    const cacheSurroundingImages = async (currentSurroundingImages, currentImageData) => {

        //Filter out all surroundingImages where image id = any of the image ids inside the currentSurroundingImages array of images
        let newSurroundingImages = currentSurroundingImages.filter((currentImage) => !surroundingImages.some((existingImage) => existingImage.id === currentImage.id));
        //newSurroundingImages = newSurroundingImages.filter((currentImage) => currentImage.id !== currentImageData.id); 

        let currentSurroundingImageIds = currentSurroundingImages.filter((currentImage) => currentImage.id !== currentImageData.id).map((img) => img.id);

        // Get the image urls for the newSurroundingImages
        //newSurroundingImages = await Promise.all(newSurroundingImages.map(async (image) => {
        
        for(let i = 0; i < newSurroundingImages.length; i++) {
            
            let img = newSurroundingImages[i];

            // Add relative distance from the current image
            // Moved below to recalc for all images
            //img.distance = getFtDistanceFromLatLon(img.Latitude_deg, img.Longitude_deg, currentImageData.Latitude_deg, currentImageData.Longitude_deg); 


            // Get nearby image URL
            const imgFullUrl = await panoramaApi.fetchPanoImage(img.Frame_folder, img.Frame_filename, img.Frame_bucket_name);
            img.imageUrl = imgFullUrl;
        }

        // Filter out points too close (leave 10ft distance)


        // Merge the newSurroundingImages with the existing surroundingImages
        // Remove currently displayed image
        //currentSurroundingImages = currentSurroundingImages.filter((img) => img.id !== imageId);
        surroundingImages = ([...surroundingImages, ...newSurroundingImages]).filter((img) => img.id !== currentImageData.id); //.sort((a, b) => a.distance - b.distance);

        // Recalculate distance and angle from the current image
        for(let i = 0; i < surroundingImages.length; i++) {
            
            let img = surroundingImages[i];

            // Add relative distance from the current image
            img.distance = getFtDistanceFromLatLon(img.Latitude_deg, img.Longitude_deg, currentImageData.Latitude_deg, currentImageData.Longitude_deg); // linearDistance(img.Latitude_deg, img.Longitude_deg, currentImageData.Latitude_deg, currentImageData.Longitude_deg) * 100000.0;

            img.yaw = getRelativeAngle(currentImageData.Latitude_deg, currentImageData.Longitude_deg, currentImageData.Heading_deg, img.Latitude_deg, img.Longitude_deg);  // = calculateAngleDeg(currentImageData.Latitude_deg, currentImageData.Longitude_deg, currentImageData.Heading_deg, img.Latitude_deg, img.Longitude_deg);
        }

        //console.log('OK - take 4: merged surroundingImages with updated distance/angle', surroundingImages); // ------------
        displaySurroundingImageMarkers(currentSurroundingImageIds);
    };



    const displaySurroundingImageMarkers = (currentSurroundingImageIds) => {

        const markersPlugs = photoSphereRef.current?.getPlugin(MarkersPlugin);
        //console.log('OK::displaySurroundingImageMarkers() surroundingImages=', surroundingImages);

        if (!markersPlugs) return; // Control not loaded - TODO sync with the plugin load

        //console.log('OK: displaySurroundingImageMarkers() - adding markers...');
        markersPlugs.clearMarkers();

        // Draw degree markers within the scene
        /*
        for (let i = 0; i < 360; i+= 20) {

            markersPlugs.addMarker({
                id: 'position' + i,
                html: '<strong>' + i + 'deg</strong>',
                position: { yaw: (i / 180) * Math.PI, pitch: 0 }, // { yaw: (i / 180) * Math.PI, pitch: 0 },
                style: {
                    color: 'rgba(255, 255, 255, 0.5)',
                    cursor: 'pointer'
                },
            });
        }
        */

        const quadrants = { Q1: [], Q2: [], Q3: [], Q4: [] };

        const displayedImages = surroundingImages.filter(obj => currentSurroundingImageIds.includes(obj.id));
        //console.log('OK::displaySurroundingImageMarkers() - displayedImages', displayedImages);

        // Allocate all images to 4 quadrants and select furthest image within each quadrant for display
        for (let i = 0; i < displayedImages.length; i++) {

            const img = displayedImages[i]; 
            let relativeAngle = img.yaw - imageData.Heading_deg; // Angle relative to the current image heading
            if (relativeAngle > 180) relativeAngle = relativeAngle - 360;
            else if (relativeAngle < -180) relativeAngle = relativeAngle + 360;

            //console.log('OK::displaySurroundingImageMarkers() - relativeAngle', relativeAngle);

            if (relativeAngle >= -20 && relativeAngle < 20) {
                quadrants.Q1.push(img); // front
            } else if (relativeAngle >= 20 && relativeAngle < 160) {
                quadrants.Q2.push(img); // right
            } else if (relativeAngle >= -160 && relativeAngle < -20) {
                quadrants.Q4.push(img); // left
            } else { 
                quadrants.Q3.push(img); // back
            }
        };

        // Within each quadrant, select the image with the largest distance property and assign to a new variable correcponding to the name of the quadrant key

        // const selectedFinalImages = Object.keys(quadrants).map((key) => {
        //     return quadrants[key].reduce((prev, current) => (prev.distance > current.distance) ? prev : current);
        // });
        const front = (quadrants.Q1.length) ? quadrants.Q1.reduce((prev, current) => (prev.distance > current.distance) ? prev : current) : null; // Pick max distance
        const back = (quadrants.Q3.length) ? quadrants.Q3.reduce((prev, current) => (prev.distance > current.distance) ? prev : current) : null; // Pick max distance
        const right = (quadrants.Q2.length) ? quadrants.Q2.reduce((prev, current) => (prev.distance < current.distance) ? prev : current) : null; // Pick min distance
        const left = (quadrants.Q4.length) ? quadrants.Q4.reduce((prev, current) => (prev.distance < current.distance) ? prev : current) : null; // Pick min distance

        // TODO: Preload images from the four quadrants for faster display

        //console.log('OK::displaySurroundingImageMarkers() - front, back, right, left', front, back, right, left);

        // Display the images from the four quadrants

        const markerTemplate = {
            ellipse: [40, 16],
            svgStyle: {
                fill       : 'rgba(255, 255, 255, 0.2)',
                stroke     : 'rgba(255, 255, 255, 0.8)',
                strokeWidth: '1px'
            }
        };


        if (front) {
            markersPlugs.addMarker({
                ...markerTemplate,
                id: front.id,
                position: { yaw: (front.yaw / 180) * Math.PI, pitch: deg2rad(-10) },
                tooltip: 'Image ID: ' + front.id,
                data: front
            });
        }

        if (back) {
            markersPlugs.addMarker({
                ...markerTemplate,
                id: back.id,
                position: { yaw: (back.yaw / 180) * Math.PI, pitch: deg2rad(-10) },
                tooltip: 'Image ID: ' + back.id,
                data: back
            });
        }

        if (right) {
            markersPlugs.addMarker({
                ...markerTemplate,
                id: right.id,
                position: { yaw: (right.yaw / 180) * Math.PI, pitch: deg2rad(-10) },
                tooltip: 'Image ID: ' + right.id,
                data: right
            });
        }

        if (left) {
            markersPlugs.addMarker({
                ...markerTemplate,
                id: left.id,
                position: { yaw: (left.yaw / 180) * Math.PI, pitch: deg2rad(-25) },
                tooltip: 'Image ID: ' + left.id,
                data: left
            });
        }


        /*
        for (let i = 0; i < surroundingImages.length; i++) {

            console.log('Id: ' + surroundingImages[i].id, 'Yaw (deg): ' + surroundingImages[i].yaw, 'Distance (ft): ' + surroundingImages[i].distance);

            markersPlugs.addMarker({
                id: surroundingImages[i].id, // Unique image ID
                ellipse: [10, 4],
                svgStyle: {
                    fill       : 'rgba(255, 255, 255, 0.4)',
                    stroke     : 'rgba(255, 255, 255, 0.8)',
                    strokeWidth: '1px'
                  },
                position: { yaw: (surroundingImages[i].yaw / 180) * Math.PI, pitch: deg2rad(surroundingImages[i].distance - 45) }, // ((surroundingImages[i].distance * 5 - 25)/ 180) * Math.PI },
                tooltip: 'Image ID: ' + surroundingImages[i].id,
                data: surroundingImages[i]
                // {
                //     "id": 742041,
                //     "collection_name": "DTE_EPSG8705_001",
                //     "collection_id": "01854",
                //     "collection_set": "1721821779",
                //     "Latitude_deg": 43.8423374389,
                //     "Longitude_deg": -82.6635625799,
                //     "Frame_folder": "DTE_EPSG8705_001/iSTARPulsar_01854/1721821779/Panoramas/",
                //     "Frame_filename": "0000000255.jpg",
                //     "Frame_bucket_name": "geospatial-nctech",
                //     "Heading_deg": 0.484,
                //     "created_date": "2024-08-12 04:09:41",
                //     "imageUrl": 'https://geospatial-nctech.s3.amazonaws.com/DTE_EPSG8705_001/iSTARPulsar_01854/1721821779/Panoramas/0000000255.jpg?AWSAccessKeyId=ASIAT7FDGPKEGYIDK665&Signature=nFOxkarWfKra888tfBdX3wtaFQI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEEIaCXVzLWVhc3QtMSJHMEUCIQDSgDquUUl8CMHK7SVC9%2FpU11Qqo4mza4JH%2BJ2Z%2FczxgQIgBCDoupgBBQyMXdJJ37DYVu6TMKt3zo7yY9S8XnTnhcMqiwMIy%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNzMwNzI2ODM2NTYiDLxDzyUFbyCLNTrsoyrfAj%2BdhCMhUmGVfxa%2F9o%2Fk5%2Bsq13LQs%2F665DGi42ylT6jPMcb8jFynH%2FV9uoEhpnV%2FL%2B8y840dd%2BCa4Z7EUAWfCgg7OMmULlgkxESljk%2BFwReKOhssiBHDT4CthrRjPOAgV1GpCyZNgYNXmKbJJ%2BJI7Yn89PSeUQgSSnRK3s2lsitQWibsMrzVebtog160GMAi7GfvtuYSRXycqYJfFnRP1oJP5dBIkQKaMnsWJCIsyjZmrfkO6CW14zwM24zTBWq0gZaVLmUOujWxwfeHsY%2FK7gGrUmDVGqT6E6Qm0zW8LBHwb1x6JBYXRerQ4P3bp46dwZ0Vdw4xThwtNop8dRevqCRLMGsfT2YdCPSqvzNZQIHNgWZZUcG0H8H%2BzFdbWc0G4ecpkg%2BIFdVqsH%2FbR9WQC9Uykt2iQqBW8VdPw55P16UbkVhEVVTvn97RwLwoqYCntQU%2FBwk3Kcrqo9C0bbnQKzDqg9C5BjqeAQUNa6RqUVPv5YYOFxFtEfNf78%2F3UgpddYppHQ%2BnK%2BIwq%2FVO0BKx4SSf0X%2FDYb2jf7%2FCIP4f1A64M6kWNWfnnnh66CLCS%2Fem0fpIm6oNLCFTkSqScNc7Q%2F%2BY%2BEbwN4G46%2FF5BNmAGlNQo%2B%2FPfKdwYrs1nQ2%2BFurjsq0iSQ%2FHWQTUTOmQFbGBsYiGhr%2FIFGl7xlzmKabsOCuXI%2FxmKZIi&Expires=1731465521'
                // }
            });
        }*/
        
    }





    // OK: 
    const handleReady = (instance) => {
        //console.log('OK: PhotoSphereViewer::handleReady()', instance);
        //const markersPlugs = instance.getPlugin(MarkersPlugin);
        if (!markersPlugs) markersPlugs = instance.getPlugin(MarkersPlugin);

        if (!markersPlugs) return;

        //console.log('OK::handleReady() - PhotoSphereViewer::marker plugin detected');
        //console.log('OK::handleReady() - PhotoSphereViewer - adding markers...');

        markersPlugs.clearMarkers();

        // Draw degree markers within the scene
        /*
        for (let i = 0; i < 360; i+= 20) {

            markersPlugs.addMarker({
                id: 'position' + i,
                html: '<strong>' + i + 'deg</strong>',
                position: { yaw: (i / 180) * Math.PI, pitch: 0 }, // { yaw: (i / 180) * Math.PI, pitch: 0 },
                style: {
                    color: 'rgba(255, 255, 255, 0.5)',
                    cursor: 'pointer'
                },
            });
        }
        */


        /*
        markersPlugs.addMarker({
            id: "imageLayer2",
            //image: "./static/bv_logo_small.png",
            //html: '<string>V E R O N i C A</strong>',
            ellipse: [10, 4],
            //size: { width: 220, height: 220 },
            // style: {
            //     color: 'rgba(255, 255, 255, 0.5)',
            //     cursor: 'pointer'
            // },
            svgStyle: {
                fill       : 'rgba(255, 255, 255, 0.4)',
                stroke     : 'rgba(255, 255, 255, 0.8)',
                strokeWidth: '1px'
              },
            position: { yaw: (86.944 / 180) * Math.PI, pitch: 0 },
            tooltip: "360 scene",
            data: {
                "id": 742041,
                "collection_name": "DTE_EPSG8705_001",
                "collection_id": "01854",
                "collection_set": "1721821779",
                "Latitude_deg": 43.8423374389,
                "Longitude_deg": -82.6635625799,
                "Frame_folder": "DTE_EPSG8705_001/iSTARPulsar_01854/1721821779/Panoramas/",
                "Frame_filename": "0000000255.jpg",
                "Frame_bucket_name": "geospatial-nctech",
                "Heading_deg": 0.484,
                "created_date": "2024-08-12 04:09:41",
                "imageUrl": 'https://geospatial-nctech.s3.amazonaws.com/DTE_EPSG8705_001/iSTARPulsar_01854/1721821779/Panoramas/0000000255.jpg?AWSAccessKeyId=ASIAT7FDGPKEGYIDK665&Signature=nFOxkarWfKra888tfBdX3wtaFQI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEEIaCXVzLWVhc3QtMSJHMEUCIQDSgDquUUl8CMHK7SVC9%2FpU11Qqo4mza4JH%2BJ2Z%2FczxgQIgBCDoupgBBQyMXdJJ37DYVu6TMKt3zo7yY9S8XnTnhcMqiwMIy%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNzMwNzI2ODM2NTYiDLxDzyUFbyCLNTrsoyrfAj%2BdhCMhUmGVfxa%2F9o%2Fk5%2Bsq13LQs%2F665DGi42ylT6jPMcb8jFynH%2FV9uoEhpnV%2FL%2B8y840dd%2BCa4Z7EUAWfCgg7OMmULlgkxESljk%2BFwReKOhssiBHDT4CthrRjPOAgV1GpCyZNgYNXmKbJJ%2BJI7Yn89PSeUQgSSnRK3s2lsitQWibsMrzVebtog160GMAi7GfvtuYSRXycqYJfFnRP1oJP5dBIkQKaMnsWJCIsyjZmrfkO6CW14zwM24zTBWq0gZaVLmUOujWxwfeHsY%2FK7gGrUmDVGqT6E6Qm0zW8LBHwb1x6JBYXRerQ4P3bp46dwZ0Vdw4xThwtNop8dRevqCRLMGsfT2YdCPSqvzNZQIHNgWZZUcG0H8H%2BzFdbWc0G4ecpkg%2BIFdVqsH%2FbR9WQC9Uykt2iQqBW8VdPw55P16UbkVhEVVTvn97RwLwoqYCntQU%2FBwk3Kcrqo9C0bbnQKzDqg9C5BjqeAQUNa6RqUVPv5YYOFxFtEfNf78%2F3UgpddYppHQ%2BnK%2BIwq%2FVO0BKx4SSf0X%2FDYb2jf7%2FCIP4f1A64M6kWNWfnnnh66CLCS%2Fem0fpIm6oNLCFTkSqScNc7Q%2F%2BY%2BEbwN4G46%2FF5BNmAGlNQo%2B%2FPfKdwYrs1nQ2%2BFurjsq0iSQ%2FHWQTUTOmQFbGBsYiGhr%2FIFGl7xlzmKabsOCuXI%2FxmKZIi&Expires=1731465521'
            }
        });
        */

        markersPlugs.addEventListener("select-marker", ({marker}) => {
            //console.log('clicked marker data', marker.data);

            if (marker?.data?.imageUrl) {
                setImageData(marker.data);
                setImageUrl(marker.data.imageUrl); // Triggers panorama display update
            }
        });
    };
    
   
    return (
        <div className="pano-wrap">
            {imageUrl &&
                <ReactPhotoSphereViewer
                    ref={photoSphereRef}
                    minFov={5}
                    navbar={[
                        'fullscreen',
                        'caption',
                        'zoom',
                        //'move'
                    ]}
                    height={'100%'}
                    width={"100%"}
                    keyboard={'always'} // OK
                    onReady={handleReady} // OK
                    plugins={plugins}>
                </ReactPhotoSphereViewer>
            }
        </div >
    );
};

export default PhotoSphereViewer;