import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { StoreContext } from '../Contexts';
import { LayoutContext } from '../Contexts/LayoutContext';

const SavePoleButton = () => {
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext);
    const [activePole] = appContext.activePole;
    const [loading] = layoutContext.loading;
    const saveAsset = appContext.saveAssetLocal;

    const getSaveIcon = () => {
        return activePole.input_source_type_id === 3 ?
            <SaveIcon />
            : <ContentCopyIcon />
    }
    return (
        <Tooltip title={activePole.input_source_type_id === 3 ? `Save Pole` : `Copy Pole`} arrow>
            <span>
                <IconButton
                    color="primary"
                    aria-label="save"
                    disabled={loading}
                    onClick={() => saveAsset(activePole.input_source_type_id !== 3)}
                    size="large">
                    {loading ? <CircularProgress size={24} /> : getSaveIcon()}
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default SavePoleButton;
