import { useContext, useEffect } from "react";
import { PositionContext, StoreContext } from "../../../Contexts";
import { mapService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { getRectangleCoordinates } from "../../../utils/reusable-functions/locationFunctions";
import { LayoutContext } from "../../../Contexts/LayoutContext";
import { useApis } from "../../../Contexts/ApiServiceContext";
import { usePointCloudUitls } from "../../../utils";

const layerId = 'pointCloudBoxes';

const PointCloudBoxesLayer = (
    { map,
        visible,
        layerRef,
        updateLayer }, ...props) => {
    const { pointCloudApi } = useApis();
    const { openPointCloud } = usePointCloudUitls();
    const { Maps } = window.Microsoft;
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext);
    const positionContext = useContext(PositionContext);
    const [activeProject] = appContext.activeProject;
    const [mainView] = layoutContext.mainView;
    const [, setLatitude] = positionContext.latitude;
    const [, setLongitude] = positionContext.longitude;
    const [, setActivePointCloud] = appContext.activePointcloud;

    const updatePointCloudBoxes = async (mapRef) => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, mapRef, visible);
        layer.clear();
        let pointClouds = await pointCloudApi.fetchPointCloudsByProject(activeProject.collection_name);
        if (Array.isArray(pointClouds)) {
            pointClouds.forEach((pointcloud) => {
                const coordsAsRectangle = getRectangleCoordinates(
                    pointcloud.minBound_lat,
                    pointcloud.minBound_lng,
                    pointcloud.maxBound_lat,
                    pointcloud.maxBound_lng
                );
                const locations = coordsAsRectangle.map(coord => new Maps.Location(coord[0], coord[1]));
                const pointCloudBoundingBox = new Maps.Polygon(locations);

                const theColor = new Maps.Color(.05, 51, 90, 134);
                pointCloudBoundingBox.setOptions({ cursor: 'cross', fillColor: theColor });
                pointCloudBoundingBox.customData = pointcloud;
                layer.add(pointCloudBoundingBox);

                Maps.Events.addHandler(pointCloudBoundingBox, 'click', async (e) => {
                    const selectedPointCloudData = await pointCloudApi
                        .fetchPointCloudByGPSLocation(activeProject.collection_name, e.location.latitude, e.location.longitude);
                    setLatitude(e.location.latitude);
                    setLongitude(e.location.longitude);
                    await openPointCloud(setActivePointCloud,
                        mainView,
                        navigate,
                        selectedPointCloudData[0],
                        e.location.latitude,
                        e.location.longitude);
                });
            });
        }
        updateLayer(layerId, layer);
    }

    useEffect(() => {
        const plotPointCloudBoxes = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await updatePointCloudBoxes(map);
            });
        }
        if (map) {
            plotPointCloudBoxes();
        }
    }, [map])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])
}

export default PointCloudBoxesLayer;