import { BaseMockApi } from "./BaseMockApi";

const baseFilePath = '/mocked_responses/projectApi'

export class ProjectMockApi extends BaseMockApi {
    constructor(setSnackbar) {
        super(setSnackbar, 'ProjectApi');
    }

    async fetchProjectList() {
        return this.get(`${baseFilePath}/get_collection_location_data.json`);
    }
}
