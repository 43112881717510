import { useContext, useEffect, } from "react";
import { PositionContext, StoreContext } from "../../../Contexts";
import { mapService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { measurementTools } from "../../../utils/reusable-functions";
import { LayoutContext } from "../../../Contexts/LayoutContext";
import { useApis } from "../../../Contexts/ApiServiceContext";
import { usePointCloudUitls } from "../../../utils";

const layerId = 'pointCloudArea';

const PointCloudPolyLineLayer = (
    { map,
        visible,
        layerRef,
        updateLayer }, ...props) => {
    const { pointCloudApi, panoramaApi } = useApis();
    const { openPointCloud } = usePointCloudUitls();
    const { Maps } = window.Microsoft;
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const positionContext = useContext(PositionContext);
    const layoutContext = useContext(LayoutContext);
    const [activeProject] = appContext.activeProject;
    const [mainView] = layoutContext.mainView;
    const [, setActivePointCloud] = appContext.activePointcloud;
    const [, setLatitude] = positionContext.latitude;
    const [, setLongitude] = positionContext.longitude;

    const updatePointCloudPolyline = async (mapRef) => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, mapRef, visible);
        layer.clear();
        let pointClouds = await panoramaApi.fetchImageryByProject(activeProject.collection_name);
        if (pointClouds && pointClouds.length > 0) {
            var coordSets = [...new Set(pointClouds.map(pointCloud => pointCloud.collection_set))];
            for (let set of coordSets) {
                var segments = [[]]; // Initialize with one segment
                pointClouds
                    .filter(pointCloud => pointCloud.collection_set === set)
                    .sort((a, b) => a.id - b.id)
                    .forEach((pointCloud, index, array) => {
                        if (index === 0) {
                            // Always include the first point in the first segment
                            segments[0].push(new Maps.Location(pointCloud.Latitude_deg, pointCloud.Longitude_deg));
                        } else {
                            // Calculate distance from the current point to the previous point
                            const prevPointCloud = array[index - 1];
                            const distance = measurementTools.calculateDistanceInFeet(prevPointCloud.Latitude_deg, prevPointCloud.Longitude_deg, pointCloud.Latitude_deg, pointCloud.Longitude_deg);
                            if (distance > 300) {
                                // If distance is greater than 300 feet, start a new segment
                                segments.push([]);
                            }
                            // Add the current point to the latest segment
                            segments[segments.length - 1].push(new Maps.Location(pointCloud.Latitude_deg, pointCloud.Longitude_deg));
                        }
                    });

                // Now, create a polyline for each segment and add it to the layer
                segments.forEach(segment => {
                    if (segment.length > 1) { // Ensure there are at least two points to draw a line
                        var line = new Maps.Polyline(segment, {
                            strokeColor: new Maps.Color(20, 0, 255, 0),
                            strokeThickness: 5 * mapRef.getZoom()
                        });
                        layer.add(line);
                        Maps.Events.addHandler(line, 'click', async (e) => {
                            const selectedPointCloudData = await pointCloudApi
                                .fetchPointCloudByGPSLocation(activeProject.collection_name, e.location.latitude, e.location.longitude);
                            setLatitude(e.location.latitude);
                            setLongitude(e.location.longitude);
                            await openPointCloud(setActivePointCloud,
                                mainView,
                                navigate,
                                selectedPointCloudData[0],
                                e.location.latitude,
                                e.location.longitude);
                        });
                    }
                });
            }
        }
        updateLayer(layerId, layer);
    }


    useEffect(() => {
        const plotPointCloudPolyline = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await updatePointCloudPolyline(map);
            });
        }
        if (map) {
            plotPointCloudPolyline();
        }
    }, [map])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])
}

export default PointCloudPolyLineLayer;