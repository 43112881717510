import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { DataGrid, GridActionsCellItem, GridEditSingleSelectCell, useGridApiRef } from '@mui/x-data-grid';
import {
    IconButton,
    Box
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StoreContext } from '../../Contexts';
import MeasurementCell from './MeasurementCell';
import UpdateAssetPopover from '../../custom-components/UpdateAssetPopover';
import { handleNestedCoordinateProperties } from '../../utils/jsonSchemaTools';
import { LayoutContext } from '../../Contexts/LayoutContext';

const CustomToolbar = ({ handleAddRow }) => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton color="primary" onClick={handleAddRow}>
            <AddCircleOutlineIcon />
        </IconButton>
    </Box>
);

const AssetInputGrid = ({ gridArchetypeOptions = ['EQUIPMENT', 'SPAN ATTACHMENT POINT', 'RISER', 'DRIP LOOP', 'GUYING', 'CROSSARM'],
    includedTableMeasurementTypes = ['height', 'azimuth'],
    excludedPopoverProperties = ['attached_assets', 'owner'] }) => {
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext);
    const [, setSnackbar] = layoutContext.snackbar;
    const [loading] = layoutContext.loading;
    const [activePole] = appContext.activePole;
    const [relatedAssets] = appContext.relatedAssets;
    const [assetSchemasDict] = appContext.assetSchemasDict;
    const [assetTypes] = appContext.assetTypes;
    const [rows, setRows] = useState([]);
    const [assetArchetypesDict, setAssetArchetypesDict] = useState({});
    const [measurementColumns, setMeasurementColumns] = useState([]);
    const gridRef = useGridApiRef();

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState('');
    const [loadingColumns, setLoadingColumns] = useState(true);

    const handleClick = (event, value) => {
        setAnchorEl(event.currentTarget);
        setSelectedAsset(value);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const archetypeOptions =
        Object.keys(assetArchetypesDict).map(id => ({
            id: parseInt(id),
            name: assetArchetypesDict[id].name
        }));

    const getRowId = (row) => row.asset_uuid ?? row.id;

    const getRowHeight = (params) => {
        let rowHeight = params.model.asset_archetype_id ? 'auto' : 20;
        return rowHeight;
    }

    const getTypeOptions = (archetypeId) => {
        let options = archetypeId === '6'
            ? [assetArchetypesDict[archetypeId]?.types.find(type => type.name === 'Down Guy')] || []
            : assetArchetypesDict[archetypeId]?.types || [];
        return options;
    };

    const fetchMeasurementColumns = () => {
        let measurementProperties = {};

        try {
            for (const assetType of assetTypes) {
                if (gridArchetypeOptions.includes(assetType.asset_archetype_name)) {
                    if ((assetType.asset_archetype_name === 'GUYING' && assetType.asset_type_name === 'Down Guy') || assetType.asset_archetype_name !== 'GUYING') {
                        const schema = assetSchemasDict[assetType.asset_properties_json_schema_id];
                        schema?.json_schema_definition
                            ? handleNestedCoordinateProperties(measurementProperties, includedTableMeasurementTypes, schema.json_schema_definition.properties)
                            : setSnackbar({
                                message: `schema not found for ${assetType.asset_type_name}`
                            });
                    }
                }
            }

            // Create columns for each measurement type
            const measurementColumns = Object.keys(measurementProperties).map(measurement => ({
                field: measurement,
                headerName: measurement.charAt(0).toUpperCase() + measurement.slice(1),
                width: measurementProperties[measurement].length > 1 ? 125 : 75,
                sortComparator: measurement === 'height' ? sortByAttachHeight : null,
                sortingOrder: ['desc', 'asc'],
                editable: false,
                type: 'string',
                disabled: (params) => !params.row.asset_type_id,
                renderCell: (params) => (
                    <MeasurementCell
                        assetId={params.row.asset_uuid || params.id}
                        measurementType={measurement}
                        assetTypeId={params.row.asset_type_id}
                        propertiesSchema={measurementProperties[measurement]}
                        handleChange={(assetId, key, value) => handleCoordinatesChange(assetId, key, value)}
                    />
                ),
            }));

            setMeasurementColumns(measurementColumns);
        } catch (error) {
            console.error('Failed to fetch schemas:', error);
        }
    };

    const getAttachHeight = (param) => {
        return param.api.getRow(param.id).asset_properties.attached_assets
            .find(asset => asset.attached_asset_source_sys_id === activePole.source_sys_id)
            .attachment_pointcloud_coordinates?.z;
    }

    const sortByAttachHeight = (v1, v2, param1, param2) => {
        let attachHeight1 = getAttachHeight(param1);
        let attachHeight2 = getAttachHeight(param2);

        // Check if the first element of the path is a number
        let aIsNumber = !isNaN(attachHeight1);
        let bIsNumber = !isNaN(attachHeight2);

        // If both paths start with a number or neither do, sort normally
        if (aIsNumber === bIsNumber) {
            return attachHeight1 > attachHeight2;
        } else {
            // If one path starts with a number and the other doesn't, the one that doesn't comes first
            return aIsNumber ? 1 : -1;
        }
    }

    const convertJsonToDict = (asset_types) => {
        const dict = {};
        asset_types.forEach((item) => {
            const { asset_archetype_name, asset_archetype_id, asset_type_id, asset_type_name } = item;
            if (gridArchetypeOptions.includes(asset_archetype_name)) {
                // Initialize the archetype entry if it doesn't exist
                if (!dict[asset_archetype_id]) {
                    dict[asset_archetype_id] = { name: asset_archetype_name, types: [] };
                }

                // Add the type to the archetype's list
                dict[asset_archetype_id].types.push({
                    name: asset_type_name,
                    id: asset_type_id
                });
            }
        });

        return dict;
    };

    const handleDeleteAsset = (assetUUID) => {
        gridRef.current.updateRows([{ id: assetUUID, _action: 'delete' }]);
        handleClose();
    };

    const handleAddRow = () => {
        let newRow = createRow();
        setRows(prev => [
            ...prev,
            newRow
        ])
    };

    const createRow = () => {
        return {
            id: gridRef.current.getRowsCount() + 1,
            asset_archetype_id: '',
            asset_type_id: '',
            asset_uuid: null,
            asset_properties: {
                attached_assets: [
                    {
                        attached_asset_source_sys_id: activePole.source_sys_id,
                        attachment_pointcloud_coordinates: null
                    }
                ]
            }
        };
    };

    const handleCoordinatesChange = (id, key, value) => {
        const updatedRow = gridRef.current.getRow(id);
        updatedRow.asset_properties = setNestedProperty(updatedRow.asset_properties, key, value, updatedRow.asset_uuid, activePole.source_sys_id);
        appContext.addToUpdatedRelatedAssets(updatedRow);
        return {
            ...updatedRow,
            asset_properties: updatedRow.updatedProperties
        }
    };

    const setNestedProperty = (obj, key, value, assetUUID = null, attachedAssetSourceSysId = null) => {
        let pathArray = key.split('.');
        if (pathArray.length === 1) {
            const pathSegment = pathArray[0];
            // Check for array index notation
            if (pathSegment.endsWith(']')) {
                const match = pathSegment.match(/(.+)\[(\d+)\]$/);
                if (match) {
                    const [_, key, index] = match;
                    if (!obj[key] || !Array.isArray(obj[key])) {
                        obj[key] = [];
                    }
                    if (obj[key][parseInt(index)] && (!attachedAssetSourceSysId || obj[key][parseInt(index)].attached_asset_uuid === attachedAssetSourceSysId)) {
                        obj[key][parseInt(index)] = value;
                    }
                }
            } else {
                obj[pathSegment] = value;
            }
            return obj;
        } else {
            const pathSegment = pathArray[0];
            if (pathSegment.endsWith(']')) {
                const match = pathSegment.match(/(.+)\[(\d+)\]$/);
                if (match) {
                    const [_, key, index] = match;
                    if (!obj[key] || !Array.isArray(obj[key])) {
                        obj[key] = [];
                    }
                    if (obj[key][parseInt(index)] === undefined) {
                        obj[key][parseInt(index)] = {};
                    }
                    if (!attachedAssetSourceSysId || obj[key][parseInt(index)].attached_asset_source_sys_id === attachedAssetSourceSysId) {
                        setNestedProperty(obj[key][parseInt(index)], pathArray.slice(1).join(), value, assetUUID, attachedAssetSourceSysId);
                    }
                }
            } else {
                if (obj[pathSegment] === undefined || typeof obj[pathSegment] !== 'object') {
                    obj[pathSegment] = {};
                }
                setNestedProperty(obj[pathSegment], pathArray.slice(1).join(), value, assetUUID, attachedAssetSourceSysId);
            }
            return obj;
        }
    }

    const updateAssetGroup = (params) => {
        let updatedRow = params.row
        updatedRow.asset_archetype_id = params.value;
        updatedRow.asset_archetype_name = assetArchetypesDict[params.value];
        updatedRow.asset_type_id = null;
        updatedRow.asset_type_name = '';
        updatedRow.asset_properties_json_schema_id = null;

        return updatedRow;
    }

    const updateAssetType = (params) => {
        let updatedRow = params.row
        updatedRow.asset_type_id = params.value;
        updatedRow.asset_type_name = assetTypes.find(type => type.asset_type_id === params.value)?.asset_type_name;
        updatedRow.asset_properties_json_schema_id = assetTypes.find(type => type.asset_type_id === params.value)?.asset_properties_json_schema_id;
        return updatedRow;
    };

    const columns = [
        {
            field: 'asset_archetype_id',
            headerName: 'Group',
            maxWidth: 50,
            editable: true,
            type: 'singleSelect',
            valueGetter: (params) => params.row.asset_archetype_id || '',
            valueSetter: updateAssetGroup,
            valueOptions: () => archetypeOptions,
            getOptionValue: (value) => value.id,
            getOptionLabel: (value) => value.name,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    {...params}
                    sx={{
                        '& .MuiSelect-select': { fontSize: '0.75rem' },
                        '& .MuiMenuItem-root li': { fontSize: '0.75rem' }
                    }}
                />
            ),
        },
        {
            field: 'asset_type_id',
            headerName: 'Type',
            editable: true,
            type: 'singleSelect',
            valueGetter: (params) => params.row.asset_type_id || '',
            valueSetter: updateAssetType,
            valueOptions: (params) => getTypeOptions(params.row.asset_archetype_id),
            getOptionValue: (value) => value.id,
            getOptionLabel: (value) => value.name,
            disabled: (params) => !params.row.asset_archetype_id,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    {...params}
                    sx={{
                        '& .MuiSelect-select': { fontSize: '0.75rem' },
                        '& .MuiMenuItem-root li': { fontSize: '0.75rem' }
                    }}
                />
            ),
        },
        {
            field: 'asset_properties.owner',
            headerName: 'Owner',
            maxWidth: 100,
            valueGetter: (params) => params.row.asset_properties.owner || '',
            valueSetter: (params) => {
                let updatedRow = params.row;
                updatedRow.asset_properties.owner = params.value;
                return updatedRow;
            },
            editable: true,
            type: 'singleSelect',
            valueOptions: (params) => {
                return assetSchemasDict[params.row.asset_properties_json_schema_id]?.json_schema_definition.properties.owner?.enum || []
            },
            disabled: (params) => !params.row.asset_type_id,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    {...params}
                    sx={{
                        '& .MuiSelect-select': { fontSize: '0.75rem' },
                        '& .MuiMenuItem-root li': { fontSize: '0.75rem' }
                    }}
                />
            ),
        },
        ...measurementColumns,
        {
            field: 'actions',
            type: 'actions',
            maxWidth: 20,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MoreVertIcon />}
                    label="More..."
                    onClick={(event) => handleClick(event, params.row)}
                    hidden={Object.keys(params.row.asset_properties).filter(prop => !excludedPopoverProperties.includes(prop)).length > 0}
                />
            ],
        },
    ];

    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                appContext.addToUpdatedRelatedAssets(newRow);
                resolve(newRow);
            }),
        [],
    );

    useEffect(() => {
        const dict = convertJsonToDict(assetTypes);
        setAssetArchetypesDict(dict);
        fetchMeasurementColumns();
        setLoadingColumns(false);
    }, []);

    useEffect(() => {
        if (relatedAssets) {
            let filteredAssets = relatedAssets.filter(asset => asset.asset_properties.attached_assets
                .some(a => a.attached_asset_source_sys_id === activePole.source_sys_id
                    && Object.keys(a).includes('attachment_pointcloud_coordinates') // Only include children directly attached to the pole
                    && asset.asset_type_id !== 23)); // Exclude anchors

            setRows(filteredAssets);
        }
    }, [relatedAssets, assetArchetypesDict, activePole]);


    return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                loading={loadingColumns || loading}
                autoHeight
                apiRef={gridRef}
                rows={rows}
                getRowHeight={getRowHeight}
                getRowId={getRowId}
                columns={columns}
                processRowUpdate={processRowUpdate}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'height', sort: 'desc' }],
                    },
                }}
                density='compact'
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        fontSize: '0.75rem',
                    },
                    '& .MuiDataGrid-cell': {
                        paddingRight: 0.5,
                        fontSize: '0.75rem'
                    },
                    width: '100%',
                    p: 0
                }}
                hideFooter
            />
            <CustomToolbar handleAddRow={handleAddRow} />
            <UpdateAssetPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                asset={selectedAsset}
                updateAsset={() => { }}
                excludedProperties={excludedPopoverProperties}
                handleDeleteAsset={handleDeleteAsset}
            />
        </div>
    );
};

export default AssetInputGrid;