/* eslint-disable */

import EsriMap from "./EsriMap";
import BingMap from './BingMap';

const bingApiKey = "AtBiHX1SI8HUvf2IfHV_a26ardSJffXmgCIhmcw-ko6okLLxb8oPgDDwZ8ycoPKk";

const BASE = process.env.REACT_APP_BASE_MAP;

function MapStage() {
  switch (BASE) {
    case "esri":
      return (
        <EsriMap></EsriMap>
      );
    default:
      return (
        <BingMap bingMapsKey={bingApiKey}></BingMap>
      )
  }
}

export default MapStage;
