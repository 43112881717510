import { useContext } from "react";
import { useApis } from "../Contexts/ApiServiceContext";
import { InteractiveSection } from "../Enums";
import { LayoutContext } from "../Contexts/LayoutContext";

const usePointCloudUtils = () => {
    const { pointCloudApi, panoramaApi } = useApis();
    const layoutContext = useContext(LayoutContext);
    const [, setSnackbar] = layoutContext.snackbar;

    async function getPointCloudBounds(collection_name, bounds) {
        const pointcloudsInArea = await pointCloudApi.fetchPointCloudsByBounds(
            collection_name,
            bounds.northEast.latitude,
            bounds.northEast.longitude,
            bounds.southWest.latitude,
            bounds.southWest.longitude
        );
        return pointcloudsInArea.length < 200 ? pointcloudsInArea : [];
    }

    async function openPointCloud(setActivePointcloud, stage, navigate, selectedPointCloudData, lat, lng, poleId = null) {
        if (selectedPointCloudData) {
            let nearestPanorama = await panoramaApi
                .fetchNearestPanoImage(selectedPointCloudData.collection_name,
                    selectedPointCloudData.central_lat,
                    selectedPointCloudData.central_lng);
            setActivePointcloud(
                {
                    collection_set: selectedPointCloudData.collection_set,
                    collection_id: selectedPointCloudData.collection_id,
                    collection_name: selectedPointCloudData.collection_name,
                    crs: selectedPointCloudData.crs,
                    wkt: selectedPointCloudData.proj4_value,
                    is_metric: selectedPointCloudData.is_metric,
                    pointcloud_id: selectedPointCloudData.id,
                    classified: Boolean(selectedPointCloudData.classified)
                });
            let urlBase = '/pointcloud-two'
            let url = `${urlBase}?collection_name=${selectedPointCloudData.collection_name}` +
                `&lat=${lat}` +
                `&lng=${lng}` +
                `&image_id=${nearestPanorama?.id}` +
                (poleId ? `&pole_id=${poleId}` : '')
            stage === InteractiveSection.Map ? window.open(url, "_self") : navigate(url);
        }
        else {
            setSnackbar(
                {
                    open: true,
                    severity: 'warning',
                    message: 'No point cloud selected'
                }
            )
        }
    }

    return {
        getPointCloudBounds,
        openPointCloud
    };
};

export default usePointCloudUtils;
