import React, { createContext, useContext } from 'react';
import {
    AssetApi,
    AssetWorkflowApi,
    GroundCollectionApi,
    PanoramaApi,
    PointCloudApi,
    ProjectApi,
    UserApi,
    WorkareaApi,
    WorkflowStatusApi
} from '../api';
import {
    AssetMockApi,
    AssetWorkflowMockApi,
    GroundCollectionMockApi,
    PointCloudMockApi,
    ProjectMockApi,
    UserMockApi,
    WorkareaMockApi,
    WorkflowStatusMockApi,
    PanoramaMockApi
} from '../api-mock';
import { LayoutContext } from './LayoutContext';

const apiMode = process.env.REACT_APP_API_MODE;


const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const layoutContext = useContext(LayoutContext);
    const [, setSnackbar] = layoutContext.snackbar;

    const services = {
        assetApi: apiMode === 'demo' ? new AssetMockApi(setSnackbar) : AssetApi,
        assetWorkflowApi: apiMode === 'demo' ? new AssetWorkflowMockApi(setSnackbar) : AssetWorkflowApi,
        groundCollectionApi: apiMode === 'demo' ? new GroundCollectionMockApi(setSnackbar) : GroundCollectionApi,
        panoramaApi: apiMode === 'demo' ? new PanoramaMockApi(setSnackbar) : PanoramaApi,
        pointCloudApi: apiMode === 'demo' ? new PointCloudMockApi(setSnackbar) : PointCloudApi,
        projectApi: apiMode === 'demo' ? new ProjectMockApi(setSnackbar) : ProjectApi,
        userApi: apiMode === 'demo' ? new UserMockApi(setSnackbar,) : UserApi,
        workareaApi: apiMode === 'demo' ? new WorkareaMockApi(setSnackbar) : WorkareaApi,
        workflowStatusApi: apiMode === 'demo' ? new WorkflowStatusMockApi(setSnackbar) : WorkflowStatusApi,
    };

    return (
        <ApiContext.Provider value={services}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApis = () => {
    return useContext(ApiContext);
};
