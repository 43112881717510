import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    IconButton,
    ButtonGroup,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Tooltip
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { StoreContext } from "../Contexts";
import { LoadingButton } from "@mui/lab";
import { useAssetServices } from "../services";

const DeletePoleButton = ({
    loading,
    onDeleteAction = () => { } }) => {
    const { deleteAsset, } = useAssetServices();
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const [potreeViewer] = appContext.potreeViewer;
    const [activePole, setActivePole] = appContext.activePole;
    const [, setPotreeMode] = appContext.potreeMode;
    const [, setMarkerPlacement] = appContext.markerPlacement;
    const [, setMeasurementMap] = appContext.measurementMap;
    const [, setPolesUpdateTrigger] = appContext.polesUpdateTrigger;
    const [deleting, setDeleting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const deletePole = async () => {
        setDeleting(true);
        await deleteAsset(activePole);
        setDeleting(false);
        clearPole();
        setModalOpen(false);
        onDeleteAction();
        setPolesUpdateTrigger(prev => !prev);
        const currentPath = window.location.pathname;
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('pole_id');
        navigate(`${currentPath}?${searchParams}`);
    }

    const clearPole = () => {
        potreeViewer?.scene.removeAllMeasurements();
        setActivePole(null);
        setPotreeMode(false);
        setMarkerPlacement([]);
        setMeasurementMap({});
    }

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    return (
        activePole.input_source_type_id === 3 &&
        <>
            <Tooltip title="Delete Pole" arrow>
                <IconButton size="small" color="error" onClick={openModal} sx={{ float: "right" }}>
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={modalOpen}
                keepMounted
                onClose={closeModal}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{`Delete Pole #${activePole.asset_uuid || activePole.id}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this pole? All associated mesurements will also be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button variant="outlined" disabled={loading} color='primary' onClick={closeModal}>Cancel</Button>
                        <LoadingButton variant="outlined" loading={deleting} color="error" onClick={deletePole}>Delete</LoadingButton>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default DeletePoleButton;