import React, { useContext, useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Container,
    CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { StoreContext } from '../Contexts';
import { useWorkareaServices } from '../services';
import { LayoutContext } from '../Contexts/LayoutContext';

function AssetOptionsComponent({
    assetIds,
    assetsUpdated = () => { },
    assignee = '',
    workflow_status_id = '',
    saving,
    setSaving,
    workareaId = null
}) {
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext)
    const { updateWorkareaAssetsAndWorkflowStatuses, updateAssetWorkflowStatuses } = useWorkareaServices();
    const [, setSnackbar] = layoutContext.snackbar;
    const [users] = appContext.users;
    const [workflowStatuses] = appContext.workflowStatuses;
    const [selectedAssetOptions, setSelectedAssetOptions] = useState({
        assignee: assignee,
        workflow_status_id: workflow_status_id,
    });


    const handleAssetsDropdownChange = (key, e) => {
        const value = e.target.value; // Directly use the value, as -1 is valid and intended
        setSelectedAssetOptions(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const updateAssetWorkflowStatusesLocal = async () => {
        let errors = {
            assignee: selectedAssetOptions.assignee === undefined,
            assetWorkflowStatus: selectedAssetOptions.workflow_status_id === undefined,
        };

        if (Object.values(errors).some(error => error)) {
            let errorMessage = 'Please select valid assignee and asset workflow status.';
            setSnackbar({
                open: true,
                message: errorMessage,
                severity: 'error'
            })
            return;
        }

        setSaving(true);
        workareaId
            ? await updateWorkareaAssetsAndWorkflowStatuses(workareaId, assetIds, selectedAssetOptions)
            : await updateAssetWorkflowStatuses(assetIds, selectedAssetOptions);
        await assetsUpdated();
        setSaving(false);
    }

    return (users && workflowStatuses &&
        <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 1, width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel id="assignee-select-label">Assignee</InputLabel>
                    <Select
                        labelId="assignee-select-label"
                        id="assignee-select"
                        value={selectedAssetOptions.assignee ?? ''}
                        onChange={(e) => handleAssetsDropdownChange('assignee', e)}
                    >
                        {users && <MenuItem value={-1}>Unassigned</MenuItem>}
                        {users
                            ? users.map(user => <MenuItem key={user.username} value={user.username}>{user.username}</MenuItem>)
                            : <MenuItem><CircularProgress /></MenuItem>}
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel id="asset-status-select-label">Assets Status</InputLabel>
                    <Select
                        labelId="asset-status-select-label"
                        id="assset-status-select"
                        value={selectedAssetOptions.workflow_status_id ?? ''}
                        onChange={(e) => handleAssetsDropdownChange('workflow_status_id', e)}
                    >
                        {workflowStatuses && <MenuItem value={-1}>None</MenuItem>}
                        {workflowStatuses
                            ? workflowStatuses
                                .filter(status => status.workflow_status_type === 'asset')
                                .map(status => <MenuItem key={status.workflow_status_id} value={status.workflow_status_id}>{status.workflow_status}</MenuItem>)
                            : <MenuItem><CircularProgress /></MenuItem>}
                    </Select>
                </FormControl>
            </Box>
            <LoadingButton variant="outlined" loading={saving} color='primary' onClick={updateAssetWorkflowStatusesLocal}>
                Save
            </LoadingButton>
        </Container>
    );
}

export default AssetOptionsComponent;
