import { BaseMockApi } from "./BaseMockApi";

const baseFilePath = '/mocked_responses/assetApi'

export class AssetMockApi extends BaseMockApi {
  constructor(setSnackbar) {
    super(setSnackbar, 'AssetApi');
  }

  async getAssetLocationsByType(assetTypeId) {
    return this.get(`${baseFilePath}/get-asset-locations-by-type-${assetTypeId}.json`);
  }

  async getAssetByUUID(asset_uuid) {
    return this.get(`${baseFilePath}/get-asset-${asset_uuid}.json`)
  }

  async getAttachedAssets(source_sys_ids, input_source_type_id) {
    let attached_assets = [];
    await Promise.all(source_sys_ids.map(async id => {
      let attached_asset = await this.get(`${baseFilePath}/get-attached-assets-${id}.json`);
      attached_assets = attached_assets.concat(attached_asset);
    }));
    return attached_assets;
  }

  async getInputSourceTypes() {
    return this.get(`${baseFilePath}/get-input-source-types.json`);
  }

  async getAssetTypes() {
    return this.get(`${baseFilePath}/get-asset-types.json`);
  }

  async getJsonSchemaDefinitions() {
    return this.get(`${baseFilePath}/get-json-catalogs.json`);
  }

  async getPolesExport(assetUUIDs) {
    let jsonData = await this.get(`${baseFilePath}/spidaCalc-export.txt`)
    // Create Blob from JSON string
    const blob = new Blob([jsonData], { type: 'application/json' });

    return blob;
  }
}
