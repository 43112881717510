import { useApis } from "../Contexts/ApiServiceContext";

const useAssetServices = () => {
    const { assetApi, assetWorkflowApi } = useApis();

    const saveAsset = async (asset, returnAsset = false) => {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (!asset.asset_uuid || asset.input_source_type_id !== 3) {
            asset.input_source_type_id = 3;
            asset.created_by = currentUser.username;
            let oldAssetUUID = asset.asset_uuid;
            asset.asset_uuid = null;
            try {
                asset.asset_uuid = await assetApi.createAsset(asset);
            } catch (error) {
                throw Error(oldAssetUUID ? `Failed to duplicate asset ${oldAssetUUID}` : `Failed to create ${asset.asset_type_name}`);
            }
        } else {
            asset.updated_by = currentUser.username;
            if (!asset.created_by) {
                asset.created_by = currentUser.username;
            }
            await assetApi.updateAsset(asset);
        }
        if (returnAsset) {
            return await assetApi.getAssetByUUID(asset.asset_uuid);
        }
    };

    const fetchAssets = async () => {
        let assets = await assetApi.getAssetLocationsByType(1);
        let assetWorkflowStatuses = await assetWorkflowApi.getAssetWorkflowStatuses(
            assets.map(a => {
                return a.id || a.asset_uuid;
            })
        );
        if (assetWorkflowStatuses) {
            assets.forEach(a => {
                let assetStatus = assetWorkflowStatuses.find(asset => [a.id, a.asset_uuid].includes(asset.asset_id));
                if (assetStatus) {
                    a.workflow_status_id = assetStatus.workflow_status_id;
                    a.workflow_status = assetStatus.workflow_status;
                    a.workflow_status_color = assetStatus.workflow_status_color;
                    a.assignee = assetStatus.assignee;
                }
            });
        }
        return assets;
    };

    const fetchAsset = async asset_id => {
        let asset = await assetApi.getAssetByUUID(asset_id);
        let assetWorkflowStatuses = await assetWorkflowApi.getAssetWorkflowStatuses([asset_id]);
        let assetStatus = assetWorkflowStatuses[0];
        asset.workflow_status_id = assetStatus?.workflow_status_id;
        asset.workflow_status = assetStatus?.workflow_status;
        asset.workflow_status_color = assetStatus?.workflow_status_color;
        asset.assignee = assetStatus?.assignee;
        return asset;
    };

    const deleteAsset = async asset => {
        return await assetApi.deleteAsset(asset.source_sys_id, asset.input_source_type_id);
    };

    const exportAssets = async poleIds => {
        try {
            const blob = await assetApi.getPolesExport(poleIds);

            // Generate filename
            const date = new Date();
            const dateString = date.toISOString().split('T')[0];
            const timeString = date.toTimeString().split(' ')[0].replace(/:/g, '-');
            const filename = `pole_export_${dateString}_${timeString}.json`;

            // Create URL for the Blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);

            return filename; // Return filename if needed

        } catch (error) {
            throw new Error(`Failed to export JSON file: ${error.message}`);
        }

    }

    return {
        saveAsset,
        fetchAssets,
        fetchAsset,
        deleteAsset,
        exportAssets
    };
};

export default useAssetServices;
