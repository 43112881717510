import React, { useEffect, useState, useContext, useCallback } from 'react';
import { GridActionsCellItem, GridEditSingleSelectCell, useGridApiRef } from '@mui/x-data-grid';
import {
    IconButton,
    Box,
    Menu,
    MenuItem
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StoreContext } from '../../Contexts';
import MeasurementCell from './MeasurementCell';
import UpdateAssetPopover from '../../custom-components/UpdateAssetPopover';
import { handleNestedCoordinateProperties } from '../../utils/jsonSchemaTools';
import { AssetApi } from '../../api';
import CustomTreeDataGrid from '../../custom-components/CustomTreeDataGrid';
import { LayoutContext } from '../../Contexts/LayoutContext';

const CustomToolbar = ({ handleAddRow }) => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton color="primary" onClick={handleAddRow}>
            <AddCircleOutlineIcon />
        </IconButton>
    </Box>
);

//TODO: consolidate logic with AssetInputGrid where possible
const AnchorInputGrid = ({ gridArchetypeOptions = ['GUYING'],
    includedTableMeasurementTypes = ['height', 'azimuth', 'length'],
    excludedPopoverProperties = ['attached_assets', 'owner', 'type', 'anchor_coordinates'] }) => {
    const appContext = useContext(StoreContext);
    const layoutContext = useContext(LayoutContext);
    const [activePole] = appContext.activePole;
    const [relatedAssets] = appContext.relatedAssets;
    const [rows, setRows] = useState([]);
    const [, setSnackbar] = layoutContext.snackbar;
    const [assetSchemasDict] = appContext.assetSchemasDict;
    const [assetTypes] = appContext.assetTypes;
    const [loading] = layoutContext.loading;
    const [assetArchetypesDict, setAssetArchetypesDict] = useState({});
    const [assetMeasurementPropertiesDict, setAssetMeasurementPropertiesDict] = useState();
    const [measurementColumns, setMeasurementColumns] = useState([]);
    const [loadingRows, setLoadingRows] = useState(true);
    const gridRef = useGridApiRef();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const popoverOpen = Boolean(popoverAnchorEl);
    const popoverId = popoverOpen ? 'simple-popover' : undefined;

    const [selectedAsset, setSelectedAsset] = useState('');

    const handleMoreClick = (event, value) => {
        setPopoverAnchorEl(event.currentTarget);
        setSelectedAsset(value);
    };

    const handleAddChildClick = (event, params) => {
        setSelectedAsset(params.row);
        setMenuAnchorEl(event.currentTarget);
    }

    const createChildRow = (value) => {
        let newRow = createRow({ typeId: value, parent: selectedAsset });
        newRow.asset_properties.attached_assets.push({ attached_asset_source_sys_id: selectedAsset.source_sys_id })
        newRow.asset_properties_json_schema_id = assetTypes.find(type => type.asset_type_id === newRow.asset_type_id).asset_properties_json_schema_id;
        setRows(prev => [
            ...prev,
            newRow
        ])
        handleMenuClose();
    }

    const handleAddRow = () => {
        let newRow = createRow({ typeId: 23 });
        newRow.asset_properties_json_schema_id = assetTypes.find(type => type.asset_type_id === newRow.asset_type_id).asset_properties_json_schema_id;
        setRows(prev => [
            ...prev,
            newRow
        ])
    };

    const createRow = ({ typeId = '', parent = null }) => {
        let id = gridRef.current.getRowsCount() + 1;
        return {
            id: id,
            asset_archetype_id: 6,
            asset_type_id: typeId,
            asset_uuid: null,
            asset_properties: {
                attached_assets: [
                    { attached_asset_source_sys_id: activePole.source_sys_id }
                ]
            },
            path: parent ? [parent.source_sys_id, id] : [id]
        };
    };

    const getRowId = (row) => row.asset_uuid ?? row.id;

    const getRowHeight = (params) => {
        let rowHeight = params.model.asset_type_id ? 'auto' : 31;
        return rowHeight;
    }

    const getTypeOptions = (archetypeId) => {
        let options = assetArchetypesDict[archetypeId]?.types || [];
        return options;
    };

    const getAssetMeasurementPropertiesDict = () => {
        if (!assetMeasurementPropertiesDict) {
            let newAssetMeasurementPropertiesDict = {};
            for (const assetType of assetTypes) {
                let measurementProperties = {};
                const schema = assetSchemasDict[assetType.asset_properties_json_schema_id];
                schema?.json_schema_definition
                    ? handleNestedCoordinateProperties(measurementProperties, includedTableMeasurementTypes, schema.json_schema_definition.properties)
                    : setSnackbar({
                        message: `schema not found for ${assetType.asset_type_name}`
                    });
                newAssetMeasurementPropertiesDict[assetType.asset_type_id] = measurementProperties;
            }
            setAssetMeasurementPropertiesDict(newAssetMeasurementPropertiesDict);
        }
    }
    const fetchMeasurementColumns = async () => {
        const measurementColumns = includedTableMeasurementTypes.map(measurement => ({
            field: measurement,
            headerName: measurement.charAt(0).toUpperCase() + measurement.slice(1),
            width: 75,
            editable: false,
            type: 'string',
            disabled: (params) => !params.row.asset_type_id,
            renderCell: (params) => renderMeasurementCell(params, measurement),
        }));

        setMeasurementColumns(measurementColumns);
    };

    const renderMeasurementCell = (params, measurement) => {
        return assetMeasurementPropertiesDict[params.row.asset_type_id][measurement]
            ? <MeasurementCell
                assetId={params.row.asset_uuid || params.id}
                measurementType={measurement}
                assetTypeId={params.row.asset_type_id}
                propertiesSchema={assetMeasurementPropertiesDict[params.row.asset_type_id][measurement]}
                handleChange={(assetId, key, value) => handleCoordinatesChange(assetId, key, value)}
            />
            : <></>
    }

    const convertJsonToDict = (asset_types) => {
        const dict = {};
        asset_types.forEach((item) => {
            const { asset_archetype_name, asset_archetype_id, asset_type_id, asset_type_name } = item;
            if (gridArchetypeOptions.includes(asset_archetype_name)) {
                if (!dict[asset_archetype_id]) {
                    dict[asset_archetype_id] = { name: asset_archetype_name, types: [] };
                }

                dict[asset_archetype_id].types.push({
                    name: asset_type_name,
                    id: asset_type_id
                });
            }
        });

        return dict;
    };

    const handleDeleteAsset = (assetUUID) => {
        gridRef.current.updateRows([{ id: assetUUID, _action: 'delete' }]);
        handlePopoverClose();
    };

    const handleCoordinatesChange = (id, key, value) => {
        const updatedRow = gridRef.current.getRow(id);
        updatedRow.asset_properties = setNestedProperty(updatedRow.asset_properties, key, value, updatedRow.asset_uuid, activePole.source_sys_id);
        appContext.addToUpdatedRelatedAssets(updatedRow);
        return {
            ...updatedRow,
            asset_properties: updatedRow.updatedProperties
        }
    };

    const setNestedProperty = (obj, key, value, assetUUID = null, attachedAssetSourceSysId = null) => {
        let pathArray = key.split('.');
        if (pathArray.length === 1) {
            const pathSegment = pathArray[0];
            // Check for array index notation
            if (pathSegment.endsWith(']')) {
                const match = pathSegment.match(/(.+)\[(\d+)\]$/);
                if (match) {
                    const [_, key, index] = match;
                    if (!obj[key] || !Array.isArray(obj[key])) {
                        obj[key] = [];
                    }
                    if (obj[key][parseInt(index)] && (!attachedAssetSourceSysId || obj[key][parseInt(index)].attached_asset_uuid === attachedAssetSourceSysId)) {
                        obj[key][parseInt(index)] = value;
                    }
                }
            } else {
                obj[pathSegment] = value;
            }
            return obj;
        } else {
            const pathSegment = pathArray[0];
            if (pathSegment.endsWith(']')) {
                const match = pathSegment.match(/(.+)\[(\d+)\]$/);
                if (match) {
                    const [_, key, index] = match;
                    if (!obj[key] || !Array.isArray(obj[key])) {
                        obj[key] = [];
                    }
                    if (obj[key][parseInt(index)] === undefined) {
                        obj[key][parseInt(index)] = {};
                    }
                    if (!attachedAssetSourceSysId || obj[key][parseInt(index)].attached_asset_source_sys_id === attachedAssetSourceSysId) {
                        setNestedProperty(obj[key][parseInt(index)], pathArray.slice(1).join(), value, assetUUID, attachedAssetSourceSysId);
                    }
                }
            } else {
                if (obj[pathSegment] === undefined || typeof obj[pathSegment] !== 'object') {
                    obj[pathSegment] = {};
                }
                setNestedProperty(obj[pathSegment], pathArray.slice(1).join(), value, assetUUID, attachedAssetSourceSysId);
            }
            return obj;
        }
    }

    const sortByAnchorParent = (v1, v2, param1, param2) => {
        let path1 = param1.api.getRow(param1.id).path;
        let path2 = param2.api.getRow(param2.id).path;
        let aPath = path1[0] === activePole.source_sys_id ? path1.slice(1) : path1;
        let bPath = path2[0] === activePole.source_sys_id ? path2.slice(1) : path2;

        // Check if the first element of the path is a number
        let aIsNumber = !isNaN(aPath[0]);
        let bIsNumber = !isNaN(bPath[0]);

        // If both paths start with a number or neither do, sort normally
        if (aIsNumber === bIsNumber) {
            // Convert arrays to strings for comparison
            aPath = aPath.join();
            bPath = bPath.join();

            if (aPath < bPath) {
                return -1;
            }
            if (aPath > bPath) {
                return 1;
            }
            return 0;
        } else {
            // If one path starts with a number and the other doesn't, the one that doesn't comes first
            return aIsNumber ? 1 : -1;
        }
    }

    const columns = [
        {
            field: 'asset_type_id',
            headerName: '',
            editable: false,
            type: 'singleSelect',
            sortComparator: sortByAnchorParent,
            sortable: false,
            valueOptions: (params) => getTypeOptions(params.row.asset_archetype_id),
            getOptionValue: (value) => value.id,
            getOptionLabel: (value) => value.name,
            disabled: (params) => !params.row.asset_archetype_id,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    {...params}
                    sx={{
                        '& .MuiSelect-select': { fontSize: '0.75rem' },
                        '& .MuiMenuItem-root li': { fontSize: '0.75rem' }
                    }}
                />
            ),
        },
        {
            field: 'asset_properties.owner',
            headerName: 'Owner',
            maxWidth: 100,
            sortable: false,
            valueGetter: (params) => params.row.asset_properties.owner || '',
            valueSetter: (params) => {
                params.row.asset_properties.owner = params.value;
                return params.row;
            },
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) =>
                assetSchemasDict[row.asset_properties_json_schema_id]?.json_schema_definition.properties.owner?.enum || [],
            disabled: (params) => !params.row.asset_type_id,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    {...params}
                    sx={{
                        '& .MuiSelect-select': { fontSize: '0.75rem' },
                        '& .MuiMenuItem-root li': { fontSize: '0.75rem' }
                    }}
                />
            ),
        },
        {
            field: 'asset_properties.type',
            headerName: 'Type',
            maxWidth: 100,
            sortable: false,
            valueGetter: ({ row }) => row.asset_properties.type || '',
            valueSetter: (params) => {
                params.row.asset_properties = setNestedProperty(params.row.asset_properties, 'type', params.value, params.row.asset_uuid || params.row.id)
                return params.row;
            },
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => assetSchemasDict[row.asset_properties_json_schema_id]?.json_schema_definition.properties.type?.enum || [],
            disabled: ({ row }) => !row.asset_type_id,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    {...params}
                    sx={{
                        '& .MuiSelect-select': { fontSize: '0.75rem' },
                        '& .MuiMenuItem-root li': { fontSize: '0.75rem' }
                    }}
                />
            ),
        },
        ...measurementColumns,
        {
            field: 'actions',
            type: 'actions',
            maxWidth: 20,
            getActions: (params) => {
                let items = []
                if (params.row.asset_type_id === 23 && params.row.asset_uuid) {
                    items.push(<GridActionsCellItem
                        icon={<AddCircleOutlineIcon />}
                        label="Add Child"
                        onClick={(event) => handleAddChildClick(event, params)}
                    />)
                }
                items.push(<GridActionsCellItem
                    icon={<MoreVertIcon />}
                    label="More Options"
                    onClick={(event) => handleMoreClick(event, params.row)}
                />)
                return items
            },
        },
    ];

    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                appContext.addToUpdatedRelatedAssets(newRow);
                resolve(newRow);
            }),
        [],
    );

    useEffect(() => {
        const dict = convertJsonToDict(assetTypes);
        setAssetArchetypesDict(dict);
        getAssetMeasurementPropertiesDict();
    }, [])

    useEffect(() => {
        if (assetMeasurementPropertiesDict) {
            fetchMeasurementColumns();
        }
    }, [assetMeasurementPropertiesDict])

    useEffect(() => {
        const getRows = async () => {
            setLoadingRows(true);
            let anchors = relatedAssets?.filter(asset => asset.asset_properties.attached_assets
                .some(a => a.attached_asset_source_sys_id === activePole.source_sys_id
                    && asset.asset_type_id === 23));
            let downGuys = anchors.length
                ? await AssetApi.getAttachedAssets([anchors.map(anchor => anchor.source_sys_id)], activePole.input_source_type_id)
                : [];
            let newDownGuys = relatedAssets?.filter(asset => !asset.asset_uuid && asset.asset_properties.attached_assets
                .some(a => anchors.map(anchor => anchor.source_sys_id).includes(a.attached_asset_source_sys_id)
                    && asset.asset_type_id === 24));
            let anchorRows = [...anchors, ...downGuys, ...newDownGuys];
            setRows(anchorRows);
            setLoadingRows(false);
        }
        if (relatedAssets) { getRows(); }
    }, [loading, relatedAssets]);


    return (
        assetMeasurementPropertiesDict &&
        <>
            <CustomTreeDataGrid
                loading={loadingRows}
                parentAssetTypeId={23}
                apiRef={gridRef}
                autoHeight
                rows={rows}
                getRowHeight={getRowHeight}
                getRowId={getRowId}
                columns={columns}
                processRowUpdate={processRowUpdate}
                disableColumnSorting
                initialState={{
                    sorting: {
                        sortModel: [
                            {
                                field: 'asset_type_id',
                                sort: null,
                            },
                        ],
                    },
                }}
                density='compact'
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        fontSize: '0.75rem',
                    },
                    '& .MuiDataGrid-cell': {
                        paddingRight: 0.5,
                        fontSize: '0.75rem'
                    },
                    width: '100%',
                    p: 0
                }}
                hideFooter
            />
            <CustomToolbar handleAddRow={handleAddRow} />
            <UpdateAssetPopover
                id={popoverId}
                open={popoverOpen}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                asset={selectedAsset}
                updateAsset={() => { }}
                excludedProperties={excludedPopoverProperties}
                handleDeleteAsset={handleDeleteAsset}
            />
            <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => createChildRow(24)}>Down Guy</MenuItem>
                {/* <MenuItem onClick={() => createChildRow(25)}>Sidewalk Brace</MenuItem> */}
            </Menu>
        </>
    );
};

export default AnchorInputGrid;