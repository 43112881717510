
import { createContext, useState } from 'react'
import { LayoutOption } from '../Enums';

export const LayoutContext = createContext(null)

export default function LayoutProvider({ children }) {
    const [layout, setLayout] = useState(LayoutOption.SideBars);
    const [mainView, setMainView] = useState(null);
    const [sideBarModules, setSidebarModules] = useState({
        leaf_header: null,
        left_top: null,
        left_bottom: null,
        right_header: null,
        right_top: null,
        right_bottom: null,
    });

    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    const layoutContext = {
        layout: [layout, setLayout],
        mainView: [mainView, setMainView],
        sideBarModules: [sideBarModules, setSidebarModules],
        loading: [loading, setLoading],
        snackbar: [snackbar, setSnackbar],
    }

    return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>
}