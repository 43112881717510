import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider, PositionProvider } from './Contexts';
import { BrowserRouter } from 'react-router-dom';
import { ApiProvider } from './Contexts/ApiServiceContext';
import LayoutProvider from './Contexts/LayoutContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LayoutProvider>
        <ApiProvider>
          <StoreProvider>
            <PositionProvider>
              <App />
            </PositionProvider>
          </StoreProvider>
        </ApiProvider>
      </LayoutProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
